import { ComponentProps } from 'react';

type Props = {
  isShort?: boolean;
} & ComponentProps<'svg'>;

export const LogoIcon = ({ isShort, ...props }: Props) =>
  !isShort ? (
    <svg
      width="186"
      height="30"
      viewBox="0 0 186 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M125.331 27.7502C125.331 28.5787 126.002 29.2502 126.831 29.2502H149.254C150.083 29.2502 150.754 28.5787 150.754 27.7502V26.9502C150.754 26.1218 150.083 25.4502 149.254 25.4502H129.786C129.51 25.4502 129.286 25.2264 129.286 24.9502V1.99023C129.286 1.16181 128.614 0.490234 127.786 0.490234H126.831C126.002 0.490234 125.331 1.16181 125.331 1.99023V27.7502Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M94.6973 27.7502C94.6973 28.5787 95.3688 29.2502 96.1973 29.2502H119.442C120.27 29.2502 120.942 28.5787 120.942 27.7502V26.9502C120.942 26.1218 120.27 25.4502 119.442 25.4502H99.1527C98.8766 25.4502 98.6527 25.2264 98.6527 24.9502V16.7902C98.6527 16.5141 98.8766 16.2902 99.1527 16.2902H118.867C119.696 16.2902 120.367 15.6187 120.367 14.7902V13.9902C120.367 13.1618 119.696 12.4902 118.867 12.4902H99.1527C98.8766 12.4902 98.6527 12.2664 98.6527 11.9902V4.79023C98.6527 4.51409 98.8766 4.29023 99.1527 4.29023H119.442C120.27 4.29023 120.942 3.61866 120.942 2.79024V1.99023C120.942 1.16181 120.27 0.490234 119.442 0.490234H96.1973C95.3688 0.490234 94.6973 1.16181 94.6973 1.99023V27.7502Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M89.4021 0.490234C88.848 0.490234 88.3391 0.795666 88.0784 1.2846L75.3701 25.1234C75.3679 25.1276 75.3634 25.1302 75.3587 25.1302C75.3538 25.1302 75.3494 25.1276 75.3472 25.1234L62.6389 1.2846C62.3782 0.795666 61.8693 0.490234 61.3152 0.490234H60.1587C59.0177 0.490234 58.2945 1.7135 58.8444 2.71318L73.0142 28.4732C73.2778 28.9525 73.7815 29.2502 74.3285 29.2502H76.3083C76.854 29.2502 77.3568 28.9538 77.6209 28.4762L91.8669 2.71616C92.4198 1.71642 91.6967 0.490234 90.5542 0.490234H89.4021Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M29.8374 27.7502C29.8374 28.5787 30.509 29.2502 31.3374 29.2502H54.5821C55.4105 29.2502 56.0821 28.5787 56.0821 27.7502V26.9502C56.0821 26.1218 55.4105 25.4502 54.5821 25.4502H34.2929C34.0167 25.4502 33.7929 25.2264 33.7929 24.9502V16.7902C33.7929 16.5141 34.0167 16.2902 34.2929 16.2902H54.0074C54.8358 16.2902 55.5074 15.6187 55.5074 14.7902V13.9902C55.5074 13.1618 54.8358 12.4902 54.0074 12.4902H34.2929C34.0167 12.4902 33.7929 12.2664 33.7929 11.9902V4.79023C33.7929 4.51409 34.0167 4.29023 34.2929 4.29023H54.5821C55.4105 4.29023 56.0821 3.61866 56.0821 2.79024V1.99023C56.0821 1.16181 55.4105 0.490234 54.5821 0.490234H31.3374C30.509 0.490234 29.8374 1.16181 29.8374 1.99023V27.7502Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M0.782715 27.7502C0.782715 28.5787 1.45429 29.2502 2.28271 29.2502H24.7064C25.5349 29.2502 26.2064 28.5787 26.2064 27.7502V26.9502C26.2064 26.1218 25.5349 25.4502 24.7064 25.4502H5.23819C4.96205 25.4502 4.73819 25.2264 4.73819 24.9502V1.99023C4.73819 1.16181 4.06662 0.490234 3.23819 0.490234H2.28271C1.45429 0.490234 0.782715 1.16181 0.782715 1.99023V27.7502Z"
        fill="white"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.478 5V25H180.002V5H159.478ZM156.912 0C155.495 0 154.347 1.11929 154.347 2.5V27.5C154.347 28.8807 155.495 30 156.912 30H182.567C183.984 30 185.133 28.8807 185.133 27.5V2.5C185.133 1.11929 183.984 0 182.567 0H156.912Z"
        fill="#2168F7"
      />
      <rect x="174.871" y="20" width="10.2621" height="10" fill="white" />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6V18H18V6H6ZM3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3Z"
        fill="#2168F7"
      />
      <rect x="15" y="15" width="9" height="9" fill="#FAFAFA" />
    </svg>
  );
