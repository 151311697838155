import { ComponentProps } from 'react';

export const Medium = (props: ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76982 3.08194C10.2458 2.69664 13.7538 2.69664 17.2298 3.08194C19.1288 3.29394 20.6598 4.78894 20.8828 6.69494C21.295 10.2196 21.295 13.7803 20.8828 17.3049C20.6598 19.2109 19.1288 20.7059 17.2308 20.9189C13.7545 21.3043 10.2462 21.3043 6.76982 20.9189C4.87082 20.7059 3.33982 19.2109 3.11682 17.3059C2.70455 13.781 2.70455 10.2199 3.11682 6.69494C3.33982 4.78894 4.87082 3.29394 6.76982 3.08194ZM16.4098 8.97994L17.2498 8.17694V7.99994H14.3478L12.2788 13.1599L9.92682 7.99994H6.88282V8.17694L7.86282 9.35694C7.91043 9.39961 7.94729 9.45291 7.9704 9.51251C7.99351 9.57212 8.00222 9.63634 7.99582 9.69994V14.3409C8.00982 14.4234 8.00426 14.508 7.9796 14.5879C7.95494 14.6678 7.91185 14.7407 7.85382 14.8009L6.74982 16.1409V16.3159H9.87682V16.1399L8.77382 14.8019C8.71524 14.7418 8.6711 14.6692 8.64477 14.5895C8.61843 14.5098 8.61058 14.4251 8.62182 14.3419V10.3279L11.3668 16.3169H11.6868L14.0438 10.3279V15.1009C14.0438 15.2279 14.0438 15.2519 13.9598 15.3359L13.1118 16.1589V16.3349H17.2288V16.1589L16.4108 15.3549C16.3752 15.328 16.3477 15.2919 16.3311 15.2505C16.3146 15.2091 16.3096 15.164 16.3168 15.1199V9.21494C16.3096 9.17093 16.3146 9.12578 16.3311 9.08436C16.3477 9.04295 16.3742 9.00685 16.4098 8.97994Z"
      fill="currentColor"
    />
  </svg>
);
