import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

export const initGA = (key: string) => {
  ReactGA.initialize(key);
};

export const sendGAEvent = (event: string | UaEventOptions, params?: any) => {
  ReactGA.event(event, params);
};
