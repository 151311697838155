import cx from 'classnames';
import { ComponentProps } from 'react';
import { SOCIAL_MEDIA } from 'shared/lib/constants/links';
import { NavbarSocialIcon } from 'shared/ui/atoms/icons/NavbarSocialIcon';

import css from './index.module.css';

export const Socials = ({ className, ...props }: ComponentProps<'nav'>) => (
  <nav className={cx(className || css.initial)} {...props}>
    <ul className={css.socials_links}>
      {SOCIAL_MEDIA.map(({ icon, link, title }) => (
        <li key={title}>
          <a className={css.socials_link} href={link} aria-label={title}>
            <NavbarSocialIcon className={css.socials_icon} name={icon!} />
          </a>
        </li>
      ))}
    </ul>
  </nav>
);
