import { ComponentProps } from 'react';

export const BurgerIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.6665 9.3335H25.3332" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.6665 16H25.3332" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.6665 22.6665H25.3332" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
