import cx from 'classnames';
import { LogoIcon } from 'shared/ui/atoms/icons/LogoIcon';

import css from './index.module.css';

type Props = {
  name: string;
  position: string;
  image: string;
  imageHighRes?: string;
};

export const Card = ({ name, position, image, imageHighRes }: Props) => (
  <div className={css.wrapper}>
    <img
      className={css.image}
      src={image}
      srcSet={`${image} 1x, ${imageHighRes || image} 2x`}
      alt={`${name}_${position}`}
      width={340}
      height={340}
      loading="lazy"
    />
    <div className={css.name_wrapper}>
      <p className={cx(css.name, 'title-medium-28')}>{name}</p>
      <LogoIcon isShort className={css.logo} />
    </div>
    <p className={cx(css.position, 'title-medium-22')}>{position}</p>
  </div>
);
