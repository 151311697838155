import { ComponentProps } from 'react';

export const PlatformRewards = (props: ComponentProps<'svg'>) => (
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2090_2043)">
      <rect y="0.5" width="54" height="54" rx="10" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2090_2043)">
        <circle cx="44.5" cy="48" r="9.5" fill="#FFB800" />
      </g>
      <path
        d="M27 26.1667C27.7333 26.1667 28.3613 25.9058 28.884 25.384C29.4067 24.8622 29.6676 24.2342 29.6667 23.5C29.6658 22.7658 29.4049 22.1382 28.884 21.6173C28.3631 21.0964 27.7351 20.8351 27 20.8333C26.2649 20.8316 25.6373 21.0929 25.1173 21.6173C24.5973 22.1418 24.336 22.7693 24.3333 23.5C24.3307 24.2307 24.592 24.8587 25.1173 25.384C25.6427 25.9093 26.2702 26.1702 27 26.1667ZM20.3333 39.5V36.8333H25.6667V32.7C24.5778 32.4556 23.6058 31.9947 22.7507 31.3173C21.8956 30.64 21.2676 29.7898 20.8667 28.7667C19.2 28.5667 17.8058 27.8391 16.684 26.584C15.5622 25.3289 15.0009 23.8564 15 22.1667V18.1667H20.3333V15.5H33.6667V18.1667H39V22.1667C39 23.8556 38.4387 25.328 37.316 26.584C36.1933 27.84 34.7991 28.5676 33.1333 28.7667C32.7333 29.7889 32.1058 30.6391 31.2507 31.3173C30.3956 31.9956 29.4231 32.4564 28.3333 32.7V36.8333H33.6667V39.5H20.3333ZM20.3333 25.9V20.8333H17.6667V22.1667C17.6667 23.0111 17.9111 23.7724 18.4 24.4507C18.8889 25.1289 19.5333 25.612 20.3333 25.9ZM33.6667 25.9C34.4667 25.6111 35.1111 25.1276 35.6 24.4493C36.0889 23.7711 36.3333 23.0102 36.3333 22.1667V20.8333H33.6667V25.9Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2090_2043"
        x="-4.7"
        y="-1.2"
        width="98.4"
        height="98.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.85" result="effect1_foregroundBlur_2090_2043" />
      </filter>
      <clipPath id="clip0_2090_2043">
        <rect y="0.5" width="54" height="54" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
