import cx from 'classnames';
import { SuccessIcon } from 'shared/ui/atoms/icons/SuccessIcon';
import { Button } from 'shared/ui/molecules/Button';

import css from './index.module.css';

type Props = {
  onClose: () => void;
};

export const Success = ({ onClose }: Props) => (
  <div className={css.inner}>
    <SuccessIcon />
    <h5 className={cx(css.title, 'title-medium-28')}>Thank You for Joining Us!</h5>
    <Button type="button" size="m" variant="secondary" onClick={onClose}>
      Ok
    </Button>
  </div>
);
