import { Id, ToastContentProps, toast } from 'react-toastify';
import { Toast } from 'shared/ui/atoms/toasts/Toast';

type Props = {
  message: string;
  type?: 'success';
};

export const toaster = (myProps: Props, toastProps?: ToastContentProps): Id =>
  toast(<Toast {...myProps} />, { ...toastProps });

toaster.success = (myProps: Props, toastProps?: ToastContentProps): Id =>
  toast.success(<Toast {...myProps} />, { ...toastProps });
