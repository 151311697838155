import { ComponentProps } from 'react';

export const SuccessIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity="0.3" width="24" height="24" rx="6" fill="black" />
    <path
      d="M8.5 12.5L10.5 14.5L15.5 9.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
