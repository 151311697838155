import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { WagmiProvider } from 'wagmi';

import { config } from './config';

const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
};

export const WalletsConfigProvider = ({ children }: Props) => (
  <WagmiProvider reconnectOnMount config={config}>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </WagmiProvider>
);
