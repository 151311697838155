import { ComponentProps } from 'react';

export const SmallCrystal = (props: ComponentProps<'svg'>) => (
  <svg
    width="56"
    height="68"
    viewBox="0 0 56 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_4_38)">
      <path d="M28.1511 22V35.82L22 33.6302L28.1511 22Z" fill="url(#paint0_linear_4_38)" />
      <path d="M28.1511 35.82L34 33.4142L28.1511 22V35.82Z" fill="url(#paint1_linear_4_38)" />
      <path d="M22 33.6302L28.1511 46V35.82L22 33.6302Z" fill="url(#paint2_linear_4_38)" />
      <path d="M34 33.4142L28.1511 46V35.82L34 33.4142Z" fill="url(#paint3_linear_4_38)" />
    </g>
    <path d="M28.2015 18V36.4267L20 33.5069L28.2015 18Z" fill="url(#paint4_linear_4_38)" />
    <path d="M28.2015 36.4267L36 33.219L28.2015 18V36.4267Z" fill="url(#paint5_linear_4_38)" />
    <path d="M20 33.5069L28.2015 50V36.4267L20 33.5069Z" fill="url(#paint6_linear_4_38)" />
    <path d="M36 33.219L28.2015 50V36.4267L36 33.219Z" fill="url(#paint7_linear_4_38)" />
    <defs>
      <filter
        id="filter0_f_4_38"
        x="0.6"
        y="0.6"
        width="54.8"
        height="66.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.7" result="effect1_foregroundBlur_4_38" />
      </filter>
      <linearGradient
        id="paint0_linear_4_38"
        x1="28.1511"
        y1="28.91"
        x2="22"
        y2="28.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_38"
        x1="31.0756"
        y1="22"
        x2="27.82"
        y2="36.5312"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4_38"
        x1="25.0756"
        y1="33.6302"
        x2="25.0756"
        y2="46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4_38"
        x1="31.0756"
        y1="46"
        x2="31.0756"
        y2="33.4142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4_38"
        x1="28.2015"
        y1="27.2134"
        x2="20"
        y2="27.2134"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4_38"
        x1="32.1007"
        y1="18"
        x2="27.76"
        y2="37.3749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4_38"
        x1="24.1007"
        y1="33.5069"
        x2="24.1007"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4_38"
        x1="32.1007"
        y1="50"
        x2="32.1007"
        y2="33.219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
    </defs>
  </svg>
);
