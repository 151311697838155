import cx from 'classnames';

import { Card } from './components/Card';
import css from './index.module.css';

export const Decoded = () => {
  const items: { title: string; text: string; bg: 'connect' | 'select' | 'collect' }[] = [
    {
      title: 'Connect',
      text: 'Deposit tokens directly into a non-custodial vault from any EVM-compatible blockchain and retain full control over your assets',
      bg: 'connect',
    },
    {
      title: 'Select',
      text: 'Choose from a curated range of top-tier DeFi vaults, each powered by cutting-edge, data-driven algorithms, tailored to diverse investment objectives',
      bg: 'select',
    },
    {
      title: 'Collect',
      text: 'Track your yield and manage every aspect of your assets seamlessly through a LevelQ dashboard designed for exceptional user experience.',
      bg: 'collect',
    },
  ];

  return (
    <section className={css.wrapper}>
      <div className={cx('content', css.inner)}>
        <h2 className={css.title}>DeFi Decoded</h2>
        <p className={cx('title-medium-22', css.text)}>
          LevelQ offers cutting-edge quantitative algorithms and ensures a seamless user experience,
          allowing everyone to leverage DeFi opportunities
        </p>
        <img
          src="/images/home/vaults/shape.png"
          alt="Shape"
          width={297}
          height={297}
          loading="lazy"
          className={css.shape}
        />
        <ul className={css.cards}>
          {items.map((el) => (
            <li key={el.title}>
              <Card className={css.card} {...el} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
