export const copyToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    try {
      const data = await navigator.clipboard.writeText(text);
      return data;
    } catch (error) {
      throw new Error('Cannot copy to clipboard');
    }
  }
  return document.execCommand('copy', true, text);
};
