import { ComponentProps } from 'react';

type Props = {
  isSmall?: boolean;
} & ComponentProps<'svg'>;

export const ComingSoonIcon = ({ isSmall, ...props }: Props) =>
  !isSmall ? (
    <svg
      width="799"
      height="799"
      viewBox="0 0 799 799"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_2272_3192)">
        <circle cx="399.811" cy="399.727" r="117.273" fill="url(#paint0_linear_2272_3192)" />
      </g>
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter1_f_2272_3192)"
      >
        <path
          d="M388.801 508.633C491.015 508.633 573.875 429.422 573.875 331.711C573.875 234 491.015 154.789 388.801 154.789C286.588 154.789 203.727 234 203.727 331.711C203.727 429.422 286.588 508.633 388.801 508.633Z"
          fill="url(#paint1_radial_2272_3192)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M388.801 489.355C479.881 489.355 553.716 418.776 553.716 331.711C553.716 244.646 479.881 174.066 388.801 174.066C297.721 174.066 223.887 244.646 223.887 331.711C223.887 418.776 297.721 489.355 388.801 489.355Z"
        fill="url(#paint2_radial_2272_3192)"
      />
      <g
        style={{
          mixBlendMode: 'screen',
        }}
      >
        <g filter="url(#filter2_d_2272_3192)">
          <path
            d="M388.8 374.74C467.284 374.74 530.908 331.504 530.908 278.171C530.908 224.837 467.284 181.602 388.8 181.602C310.317 181.602 246.693 224.837 246.693 278.171C246.693 331.504 310.317 374.74 388.8 374.74Z"
            fill="url(#paint3_radial_2272_3192)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <g filter="url(#filter3_d_2272_3192)">
        <path
          d="M388.802 511.737C472.134 511.737 539.687 443.954 539.687 360.341C539.687 276.727 472.134 208.945 388.802 208.945C305.471 208.945 237.917 276.727 237.917 360.341C237.917 443.954 305.471 511.737 388.802 511.737Z"
          fill="url(#paint4_linear_2272_3192)"
        />
      </g>
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter4_f_2272_3192)"
      >
        <path
          d="M607.503 393.783C627.4 375.706 590.742 302.555 525.625 230.396C460.507 158.238 391.59 114.396 371.693 132.473C351.796 150.55 388.454 223.701 453.572 295.859C518.689 368.018 587.606 411.86 607.503 393.783Z"
          fill="url(#paint5_diamond_2272_3192)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M553.318 317.06C562.165 309.022 545.865 276.498 516.913 244.415C487.96 212.332 457.318 192.838 448.472 200.876C439.625 208.913 455.924 241.437 484.877 273.521C513.829 305.604 544.472 325.097 553.318 317.06Z"
        fill="url(#paint6_radial_2272_3192)"
      />
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter5_f_2272_3192)"
      >
        <path
          d="M566.542 328.476C642.042 250.084 671.475 155.728 632.283 117.726C593.092 79.7243 500.116 112.467 424.616 190.859C349.117 269.252 319.683 363.608 358.875 401.609C398.067 439.611 491.042 406.868 566.542 328.476Z"
          fill="url(#paint7_diamond_2272_3192)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        d="M270.586 366.968C270.586 366.968 270.551 367.035 270.515 367.068C269.265 367.551 267.445 368.067 265.285 368.067C260.483 368.067 255.325 365.919 255.325 361.19C255.325 355.129 261.893 353.813 265.124 353.813C267.427 353.813 269.337 354.246 270.515 354.596C270.551 354.612 270.586 354.646 270.586 354.712L270.783 357.293C270.783 357.293 270.747 357.426 270.658 357.426C270.64 357.426 270.622 357.41 270.604 357.41C269.944 357.16 267.748 356.394 265.106 356.394C261.465 356.394 258.074 358.342 258.074 361.19C258.074 364.587 262.768 365.486 265.267 365.486C267.766 365.486 269.872 364.537 270.693 364.121C270.711 364.121 270.729 364.104 270.747 364.104C270.818 364.104 270.89 364.154 270.872 364.237L270.586 366.985V366.968Z"
        fill="white"
      />
      <path
        d="M281.241 360.932C281.241 355.847 286.657 353.813 290.303 353.813C294.215 353.813 299.383 356.08 299.383 360.932C299.383 365.183 295.489 368.067 290.303 368.067C286.586 368.067 281.241 365.483 281.241 360.932ZM283.985 360.932C283.985 363.449 286.816 365.483 290.303 365.483C293.79 365.483 296.639 363.432 296.639 360.932C296.639 358.431 293.79 356.397 290.303 356.397C286.816 356.397 283.985 358.448 283.985 360.932Z"
        fill="white"
      />
      <path
        d="M326.816 353.813H328.799C328.923 353.813 328.994 353.92 328.994 354.026V368.164C328.994 368.288 328.923 368.377 328.799 368.377H326.462C326.338 368.377 326.268 368.27 326.268 368.164V358.857C326.268 358.857 326.232 358.804 326.215 358.804C326.197 358.804 326.179 358.804 326.161 358.821L320.533 365.624C320.533 365.624 320.426 365.695 320.373 365.695H319.152C319.152 365.695 319.01 365.659 318.975 365.624L313.346 358.821L313.311 358.804C313.275 358.804 313.258 358.821 313.258 358.857V368.164C313.258 368.288 313.151 368.377 313.045 368.377H310.744C310.62 368.377 310.532 368.27 310.532 368.164V354.026C310.532 353.902 310.638 353.813 310.744 353.813H312.709C312.709 353.813 312.833 353.849 312.868 353.884L319.736 362.196C319.736 362.196 319.789 362.214 319.842 362.196L326.692 353.884C326.692 353.884 326.799 353.813 326.852 353.813H326.816Z"
        fill="white"
      />
      <path
        d="M339.687 368.067C339.687 368.067 339.554 368.015 339.554 367.928V353.952C339.554 353.952 339.604 353.813 339.687 353.813H342.012C342.012 353.813 342.145 353.865 342.145 353.952V367.928C342.145 367.928 342.095 368.067 342.012 368.067H339.687Z"
        fill="white"
      />
      <path
        d="M354.004 368.067C353.897 368.067 353.808 367.963 353.808 367.876V354.022C353.808 353.917 353.915 353.83 354.004 353.83H356.034C356.034 353.83 356.159 353.848 356.177 353.883L366.506 363.681C366.506 363.681 366.542 363.699 366.56 363.699C366.578 363.699 366.613 363.681 366.613 363.646V354.004C366.595 353.9 366.684 353.813 366.791 353.813H369.16C369.267 353.813 369.356 353.883 369.356 354.004V367.858C369.374 367.98 369.285 368.067 369.16 368.067H367.236C367.236 368.067 367.112 368.05 367.094 368.015L356.658 358.112C356.658 358.112 356.551 358.112 356.551 358.147V367.876C356.551 367.98 356.444 368.067 356.337 368.067H353.986H354.004Z"
        fill="white"
      />
      <path
        d="M381.021 361.19C381.021 355.761 386.721 353.813 390.391 353.813C393.685 353.813 395.818 354.279 396.296 354.412C396.381 354.429 396.416 354.479 396.416 354.546L396.569 357.243C396.569 357.243 396.501 357.393 396.416 357.393C396.399 357.393 396.364 357.393 396.347 357.377C395.289 356.877 393.292 356.411 390.391 356.411C386.755 356.411 383.666 358.192 383.666 361.207C383.666 364.604 388.155 365.503 390.544 365.503C392.558 365.503 394.214 364.887 395.101 364.47C395.17 364.437 395.221 364.354 395.221 364.287V362.389C395.221 362.389 395.17 362.239 395.067 362.239H389.094C388.991 362.239 388.94 362.189 388.94 362.089V359.824C388.94 359.724 388.991 359.675 389.094 359.675H397.713C397.815 359.675 397.866 359.724 397.866 359.824V365.736C397.866 365.736 397.832 365.869 397.764 365.919C397.201 366.269 394.333 368.067 390.544 368.067C385.953 368.067 381.021 365.919 381.021 361.19Z"
        fill="white"
      />
      <path
        d="M423.218 366.835C423.218 366.835 423.168 366.802 423.168 366.768L422.886 363.887C422.886 363.887 422.919 363.754 422.986 363.754C423.002 363.754 423.019 363.771 423.036 363.771C423.717 364.171 426.358 365.486 430.727 365.486C433.568 365.486 435.329 364.77 435.329 363.738C435.329 363.005 433.784 362.339 429.963 362.339C422.803 362.339 422.487 359.341 422.487 358.259C422.487 354.696 426.823 353.813 430.578 353.813C433.967 353.813 436.31 354.829 436.725 355.012C436.758 355.012 436.775 355.045 436.775 355.079L436.957 357.876C436.957 357.876 436.924 357.993 436.858 357.993C436.841 357.993 436.825 357.976 436.808 357.976C435.927 357.426 433.452 356.411 430.561 356.411C425.86 356.411 425.046 357.51 425.046 358.259C425.046 359.308 426.906 359.758 429.947 359.758C432.671 359.758 438.037 360.057 438.037 363.838C438.037 364.703 437.423 368.067 430.711 368.067C426.325 368.067 423.634 367.018 423.202 366.835H423.218Z"
        fill="white"
      />
      <path
        d="M449.7 360.932C449.7 355.847 455.116 353.813 458.762 353.813C462.673 353.813 467.841 356.08 467.841 360.932C467.841 365.183 463.948 368.067 458.762 368.067C455.045 368.067 449.7 365.483 449.7 360.932ZM452.443 360.932C452.443 363.449 455.275 365.483 458.762 365.483C462.248 365.483 465.098 363.432 465.098 360.932C465.098 358.431 462.248 356.397 458.762 356.397C455.275 356.397 452.443 358.448 452.443 360.932Z"
        fill="white"
      />
      <path
        d="M476.912 360.932C476.912 355.847 482.328 353.813 485.974 353.813C489.886 353.813 495.054 356.08 495.054 360.932C495.054 365.183 491.16 368.067 485.974 368.067C482.257 368.067 476.912 365.483 476.912 360.932ZM479.655 360.932C479.655 363.449 482.487 365.483 485.974 365.483C489.461 365.483 492.31 363.432 492.31 360.932C492.31 358.431 489.461 356.397 485.974 356.397C482.487 356.397 479.655 358.448 479.655 360.932Z"
        fill="white"
      />
      <path
        d="M506.912 368.067C506.805 368.067 506.716 367.963 506.716 367.876V354.022C506.716 353.917 506.823 353.83 506.912 353.83H508.942C508.942 353.83 509.067 353.848 509.085 353.883L519.415 363.681C519.415 363.681 519.45 363.699 519.468 363.699C519.486 363.699 519.521 363.681 519.521 363.646V354.004C519.504 353.9 519.593 353.813 519.699 353.813H522.068C522.175 353.813 522.264 353.883 522.264 354.004V367.858C522.282 367.98 522.193 368.067 522.068 368.067H520.145C520.145 368.067 520.02 368.05 520.002 368.015L509.566 358.112C509.566 358.112 509.459 358.112 509.459 358.147V367.876C509.459 367.98 509.352 368.067 509.245 368.067H506.894H506.912Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_2272_3192"
          x="0.937592"
          y="0.854095"
          width="797.746"
          height="797.746"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="140.8" result="effect1_foregroundBlur_2272_3192" />
        </filter>
        <filter
          id="filter1_f_2272_3192"
          x="169.727"
          y="120.789"
          width="438.148"
          height="421.844"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="17" result="effect1_foregroundBlur_2272_3192" />
        </filter>
        <filter
          id="filter2_d_2272_3192"
          x="242.693"
          y="181.602"
          width="292.215"
          height="201.138"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2272_3192" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2272_3192"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2272_3192"
          x="203.417"
          y="188.445"
          width="358.77"
          height="359.792"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-6" dy="8" />
          <feGaussianBlur stdDeviation="14.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2272_3192" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2272_3192"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_2272_3192"
          x="342.579"
          y="104.704"
          width="294.038"
          height="316.848"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="11.85" result="effect1_foregroundBlur_2272_3192" />
        </filter>
        <filter
          id="filter5_f_2272_3192"
          x="314.422"
          y="74.7916"
          width="362.314"
          height="369.752"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="13.55" result="effect1_foregroundBlur_2272_3192" />
        </filter>
        <linearGradient
          id="paint0_linear_2272_3192"
          x1="399.811"
          y1="282.454"
          x2="399.811"
          y2="517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5649F7" />
          <stop offset="1" stopColor="#322B91" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(389.399 332.747) scale(185.359 177.609)"
        >
          <stop offset="0.66" stopColor="#505EB3" />
          <stop offset="1" stopColor="#46529D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.88549 332.747) scale(165.162 158.257)"
        >
          <stop offset="0.66" stopColor="#505EB3" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(390.515 277.34) scale(142.843 97.0442)"
        >
          <stop offset="0.66" stopColor="#B900D4" />
          <stop offset="1" stopColor="#89009D" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_2272_3192"
          x1="388.802"
          y1="511.817"
          x2="388.802"
          y2="165.833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E1117" />
          <stop offset="0.96" stopColor="#252B36" />
        </linearGradient>
        <radialGradient
          id="paint5_diamond_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(507.023 247.836) rotate(47.9364) scale(175.811 70.4757)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="1" stopColor="#4F5CB0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(92.3038 630.597) rotate(47.9364) scale(78.1729 22.3275)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_diamond_2272_3192"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(499.598 264.349) rotate(133.923) scale(196.896 40.4196)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="0.786807" stopColor="#3C4787" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="375"
      height="720"
      viewBox="0 0 375 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_2272_3678)">
        <circle cx="195.694" cy="359.944" r="78.0563" fill="url(#paint0_linear_2272_3678)" />
      </g>
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter1_f_2272_3678)"
      >
        <path
          d="M188.366 432.431C256.399 432.431 311.551 379.709 311.551 314.672C311.551 249.636 256.399 196.914 188.366 196.914C120.333 196.914 65.1816 249.636 65.1816 314.672C65.1816 379.709 120.333 432.431 188.366 432.431Z"
          fill="url(#paint1_radial_2272_3678)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M188.366 419.6C248.988 419.6 298.132 372.622 298.132 314.672C298.132 256.723 248.988 209.745 188.366 209.745C127.744 209.745 78.6001 256.723 78.6001 314.672C78.6001 372.622 127.744 419.6 188.366 419.6Z"
        fill="url(#paint2_radial_2272_3678)"
      />
      <g
        style={{
          mixBlendMode: 'screen',
        }}
      >
        <g filter="url(#filter2_d_2272_3678)">
          <path
            d="M188.365 343.312C240.604 343.312 282.951 314.535 282.951 279.037C282.951 243.538 240.604 214.761 188.365 214.761C136.127 214.761 93.7793 243.538 93.7793 279.037C93.7793 314.535 136.127 343.312 188.365 343.312Z"
            fill="url(#paint3_radial_2272_3678)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <g filter="url(#filter3_d_2272_3678)">
        <path
          d="M188.367 434.496C243.832 434.496 288.795 389.381 288.795 333.728C288.795 278.075 243.832 232.96 188.367 232.96C132.902 232.96 87.9385 278.075 87.9385 333.728C87.9385 389.381 132.902 434.496 188.367 434.496Z"
          fill="url(#paint4_linear_2272_3678)"
        />
      </g>
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter4_f_2272_3678)"
      >
        <path
          d="M333.933 355.987C347.176 343.955 322.777 295.266 279.435 247.238C236.093 199.21 190.222 170.029 176.979 182.061C163.736 194.093 188.135 242.781 231.477 290.81C274.819 338.838 320.69 368.019 333.933 355.987Z"
          fill="url(#paint5_diamond_2272_3678)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        style={{
          mixBlendMode: 'screen',
        }}
        d="M297.868 304.921C303.756 299.571 292.907 277.923 273.636 256.569C254.366 235.214 233.971 222.24 228.082 227.59C222.194 232.939 233.043 254.587 252.314 275.942C271.584 297.296 291.979 310.271 297.868 304.921Z"
        fill="url(#paint6_radial_2272_3678)"
      />
      <g
        style={{
          mixBlendMode: 'screen',
        }}
        filter="url(#filter5_f_2272_3678)"
      >
        <path
          d="M306.67 312.519C356.922 260.342 376.512 197.539 350.427 172.245C324.341 146.951 262.457 168.745 212.204 220.922C161.952 273.1 142.362 335.903 168.447 361.196C194.533 386.49 256.417 364.697 306.67 312.519Z"
          fill="url(#paint7_diamond_2272_3678)"
          style={{
            mixBlendMode: 'screen',
          }}
        />
      </g>
      <path
        d="M109.683 338.139C109.683 338.139 109.659 338.184 109.635 338.206C108.804 338.527 107.592 338.871 106.154 338.871C102.958 338.871 99.5249 337.441 99.5249 334.293C99.5249 330.259 103.897 329.383 106.047 329.383C107.58 329.383 108.851 329.671 109.635 329.904C109.659 329.915 109.683 329.937 109.683 329.982L109.814 331.7C109.814 331.7 109.79 331.788 109.73 331.788C109.719 331.788 109.707 331.777 109.695 331.777C109.255 331.611 107.794 331.101 106.036 331.101C103.612 331.101 101.355 332.398 101.355 334.293C101.355 336.554 104.479 337.153 106.142 337.153C107.806 337.153 109.208 336.521 109.754 336.244C109.766 336.244 109.778 336.233 109.79 336.233C109.837 336.233 109.885 336.266 109.873 336.322L109.683 338.15V338.139Z"
        fill="white"
      />
      <path
        d="M116.775 334.122C116.775 330.737 120.38 329.383 122.807 329.383C125.41 329.383 128.85 330.892 128.85 334.122C128.85 336.951 126.258 338.871 122.807 338.871C120.333 338.871 116.775 337.151 116.775 334.122ZM118.601 334.122C118.601 335.797 120.486 337.151 122.807 337.151C125.127 337.151 127.024 335.786 127.024 334.122C127.024 332.457 125.127 331.103 122.807 331.103C120.486 331.103 118.601 332.468 118.601 334.122Z"
        fill="white"
      />
      <path
        d="M147.109 329.383H148.429C148.511 329.383 148.559 329.454 148.559 329.525V338.935C148.559 339.018 148.511 339.077 148.429 339.077H146.874C146.791 339.077 146.744 339.006 146.744 338.935V332.741C146.744 332.741 146.721 332.705 146.709 332.705C146.697 332.705 146.685 332.705 146.674 332.717L142.927 337.245C142.927 337.245 142.856 337.292 142.821 337.292H142.008C142.008 337.292 141.914 337.268 141.89 337.245L138.144 332.717L138.12 332.705C138.097 332.705 138.085 332.717 138.085 332.741V338.935C138.085 339.018 138.014 339.077 137.943 339.077H136.412C136.329 339.077 136.271 339.006 136.271 338.935V329.525C136.271 329.442 136.341 329.383 136.412 329.383H137.72C137.72 329.383 137.802 329.407 137.826 329.431L142.397 334.963C142.397 334.963 142.432 334.975 142.468 334.963L147.027 329.431C147.027 329.431 147.098 329.383 147.133 329.383H147.109Z"
        fill="white"
      />
      <path
        d="M155.676 338.871C155.676 338.871 155.587 338.836 155.587 338.778V329.476C155.587 329.476 155.621 329.383 155.676 329.383H157.223C157.223 329.383 157.312 329.418 157.312 329.476V338.778C157.312 338.778 157.279 338.871 157.223 338.871H155.676Z"
        fill="white"
      />
      <path
        d="M165.205 338.871C165.134 338.871 165.075 338.801 165.075 338.743V329.522C165.075 329.453 165.146 329.395 165.205 329.395H166.556C166.556 329.395 166.639 329.406 166.651 329.43L173.527 335.952C173.527 335.952 173.55 335.963 173.562 335.963C173.574 335.963 173.598 335.952 173.598 335.928V329.511C173.586 329.441 173.645 329.383 173.716 329.383H175.293C175.364 329.383 175.423 329.43 175.423 329.511V338.732C175.435 338.813 175.376 338.871 175.293 338.871H174.013C174.013 338.871 173.93 338.859 173.918 338.836L166.971 332.245C166.971 332.245 166.9 332.245 166.9 332.268V338.743C166.9 338.813 166.829 338.871 166.758 338.871H165.193H165.205Z"
        fill="white"
      />
      <path
        d="M183.188 334.293C183.188 330.68 186.982 329.383 189.424 329.383C191.617 329.383 193.037 329.694 193.355 329.782C193.412 329.793 193.434 329.827 193.434 329.871L193.537 331.667C193.537 331.667 193.491 331.766 193.434 331.766C193.423 331.766 193.4 331.766 193.389 331.755C192.685 331.423 191.355 331.112 189.424 331.112C187.005 331.112 184.948 332.298 184.948 334.304C184.948 336.565 187.936 337.164 189.526 337.164C190.867 337.164 191.969 336.754 192.56 336.477C192.605 336.455 192.639 336.399 192.639 336.355V335.091C192.639 335.091 192.605 334.992 192.537 334.992H188.561C188.493 334.992 188.459 334.958 188.459 334.892V333.384C188.459 333.318 188.493 333.285 188.561 333.285H194.298C194.366 333.285 194.4 333.318 194.4 333.384V337.319C194.4 337.319 194.377 337.408 194.332 337.441C193.957 337.674 192.048 338.871 189.526 338.871C186.471 338.871 183.188 337.441 183.188 334.293Z"
        fill="white"
      />
      <path
        d="M211.274 338.051C211.274 338.051 211.241 338.028 211.241 338.006L211.053 336.089C211.053 336.089 211.075 336 211.119 336C211.13 336 211.141 336.011 211.152 336.011C211.606 336.277 213.364 337.153 216.272 337.153C218.163 337.153 219.335 336.676 219.335 335.989C219.335 335.501 218.307 335.058 215.764 335.058C210.998 335.058 210.788 333.063 210.788 332.343C210.788 329.971 213.674 329.383 216.173 329.383C218.428 329.383 219.988 330.059 220.264 330.181C220.286 330.181 220.297 330.203 220.297 330.226L220.419 332.088C220.419 332.088 220.397 332.165 220.353 332.165C220.341 332.165 220.33 332.154 220.319 332.154C219.733 331.788 218.086 331.112 216.162 331.112C213.032 331.112 212.49 331.844 212.49 332.343C212.49 333.041 213.729 333.34 215.752 333.34C217.566 333.34 221.138 333.54 221.138 336.056C221.138 336.632 220.728 338.871 216.261 338.871C213.342 338.871 211.551 338.173 211.263 338.051H211.274Z"
        fill="white"
      />
      <path
        d="M228.9 334.122C228.9 330.737 232.505 329.383 234.932 329.383C237.535 329.383 240.975 330.892 240.975 334.122C240.975 336.951 238.383 338.871 234.932 338.871C232.458 338.871 228.9 337.151 228.9 334.122ZM230.726 334.122C230.726 335.797 232.611 337.151 234.932 337.151C237.252 337.151 239.149 335.786 239.149 334.122C239.149 332.457 237.252 331.103 234.932 331.103C232.611 331.103 230.726 332.468 230.726 334.122Z"
        fill="white"
      />
      <path
        d="M247.012 334.122C247.012 330.737 250.617 329.383 253.044 329.383C255.647 329.383 259.087 330.892 259.087 334.122C259.087 336.951 256.495 338.871 253.044 338.871C250.57 338.871 247.012 337.151 247.012 334.122ZM248.838 334.122C248.838 335.797 250.723 337.151 253.044 337.151C255.365 337.151 257.261 335.786 257.261 334.122C257.261 332.457 255.365 331.103 253.044 331.103C250.723 331.103 248.838 332.468 248.838 334.122Z"
        fill="white"
      />
      <path
        d="M266.981 338.871C266.909 338.871 266.85 338.801 266.85 338.743V329.522C266.85 329.453 266.921 329.395 266.981 329.395H268.332C268.332 329.395 268.415 329.406 268.427 329.43L275.302 335.952C275.302 335.952 275.326 335.963 275.338 335.963C275.349 335.963 275.373 335.952 275.373 335.928V329.511C275.361 329.441 275.42 329.383 275.492 329.383H277.068C277.139 329.383 277.199 329.43 277.199 329.511V338.732C277.21 338.813 277.151 338.871 277.068 338.871H275.788C275.788 338.871 275.705 338.859 275.693 338.836L268.747 332.245C268.747 332.245 268.676 332.245 268.676 332.268V338.743C268.676 338.813 268.604 338.871 268.533 338.871H266.969H266.981Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_2272_3678"
          x="-163.962"
          y="0.287689"
          width="719.312"
          height="719.312"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="140.8" result="effect1_foregroundBlur_2272_3678" />
        </filter>
        <filter
          id="filter1_f_2272_3678"
          x="31.1816"
          y="162.914"
          width="314.369"
          height="303.517"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="17" result="effect1_foregroundBlur_2272_3678" />
        </filter>
        <filter
          id="filter2_d_2272_3678"
          x="89.7793"
          y="214.761"
          width="197.172"
          height="136.552"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2272_3678" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2272_3678"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2272_3678"
          x="53.4385"
          y="212.46"
          width="257.856"
          height="258.537"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-6" dy="8" />
          <feGaussianBlur stdDeviation="14.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2272_3678" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2272_3678"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_2272_3678"
          x="149.675"
          y="155.652"
          width="211.561"
          height="226.744"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="11.85" result="effect1_foregroundBlur_2272_3678" />
        </filter>
        <filter
          id="filter5_f_2272_3678"
          x="129.797"
          y="134.606"
          width="259.279"
          height="264.23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="13.55" result="effect1_foregroundBlur_2272_3678" />
        </filter>
        <linearGradient
          id="paint0_linear_2272_3678"
          x1="195.694"
          y1="281.888"
          x2="195.694"
          y2="438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5649F7" />
          <stop offset="1" stopColor="#322B91" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(188.764 315.362) scale(123.374 118.216)"
        >
          <stop offset="0.66" stopColor="#505EB3" />
          <stop offset="1" stopColor="#46529D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-69.1628 315.362) scale(109.931 105.335)"
        >
          <stop offset="0.66" stopColor="#505EB3" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(189.507 278.483) scale(95.0757 64.5922)"
        >
          <stop offset="0.66" stopColor="#B900D4" />
          <stop offset="1" stopColor="#89009D" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_2272_3678"
          x1="188.367"
          y1="434.55"
          x2="188.367"
          y2="204.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E1117" />
          <stop offset="0.96" stopColor="#252B36" />
        </linearGradient>
        <radialGradient
          id="paint5_diamond_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(267.054 258.845) rotate(47.9364) scale(117.019 46.9082)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="1" stopColor="#4F5CB0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-8.98109 513.61) rotate(47.9364) scale(52.0315 14.8611)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_diamond_2272_3678"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(262.112 269.837) rotate(133.923) scale(131.053 26.9031)"
        >
          <stop stopColor="#4F5CB0" />
          <stop offset="0.786807" stopColor="#3C4787" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
