import { ComponentProps } from 'react';

export const TokenIndices = (props: ComponentProps<'svg'>) => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2087_1675)">
      <rect y="0.5" width="80" height="80" rx="15" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2087_1675)">
        <circle cx="71" cy="74.5" r="19" fill="#FAFF00" />
      </g>
      <path
        d="M56 47V32L43.5 24.969M36.5 24.969L24 32V47M27 51.688L40 59L48 54.5L53 51.688"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37 35.75L34 37.5V44.5L37 46.25L40 48L43 46.25L46 44.5V37.5L43 35.75L40 34L37 35.75Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 34V27M46 44L53 48M34 44L27 48"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 27C41.6569 27 43 25.6569 43 24C43 22.3431 41.6569 21 40 21C38.3431 21 37 22.3431 37 24C37 25.6569 38.3431 27 40 27Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 53C25.6569 53 27 51.6569 27 50C27 48.3431 25.6569 47 24 47C22.3431 47 21 48.3431 21 50C21 51.6569 22.3431 53 24 53Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56 53C57.6569 53 59 51.6569 59 50C59 48.3431 57.6569 47 56 47C54.3431 47 53 48.3431 53 50C53 51.6569 54.3431 53 56 53Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2087_1675"
        x="-28.1"
        y="-24.6"
        width="198.2"
        height="198.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="40.05" result="effect1_foregroundBlur_2087_1675" />
      </filter>
      <clipPath id="clip0_2087_1675">
        <rect y="0.5" width="80" height="80" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
