import { Form } from './components/Form';
import css from './index.module.css';

export const WhitelistSection = () => (
  <section className={css.wrapper}>
    <div className="content">
      <div className={css.inner}>
        <h2 className={css.title}>
          Boost your DeFi growth.
          <br />
          Join the waitlist
        </h2>
        <Form />
      </div>
    </div>
  </section>
);
