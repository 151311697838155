import { NavigationItem } from 'entities/types/common';
import { SocialMedia } from 'entities/types/enum';

export const SOCIAL_MEDIA: NavigationItem[] = [
  {
    title: 'Telegram',
    link: 'https://t.me/LevelQFin',
    icon: SocialMedia.Telegram,
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/LevelQFinance',
    icon: SocialMedia.X,
  },
  {
    title: 'Medium',
    link: 'https://medium.com/@LevelQ',
    icon: SocialMedia.Medium,
  },
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/levelqfinance/',
    icon: SocialMedia.LinkedIn,
  },
];

export const NAV_ITEMS = [
  {
    title: 'About',
    to: '/about',
  },
  {
    title: 'Docs',
    href: 'https://levelq-finance.gitbook.io/home/',
  },
  {
    title: 'Social Media',
    submenu: SOCIAL_MEDIA,
  },
];

export const COMPANY_LINKS: NavigationItem[] = [
  {
    title: 'Docs',
    link: '#',
  },
];

export const FOOTER_LINKS = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'About Us',
    to: '/about',
  },
  {
    title: 'Docs',
    href: 'https://levelq-finance.gitbook.io/home/',
  },
  {
    title: 'Privacy Policy',
    href: 'https://levelq-finance.gitbook.io/home/other/privacy-policy',
  },
];
