import { ComponentProps } from 'react';

export const ArrowDropDown = (props: ComponentProps<'svg'>) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.19206 0.230466L8.90654 4.68785C9.34076 5.20891 8.97024 6 8.29197 6L1.70803 6C1.02976 6 0.659236 5.20891 1.09345 4.68785L4.80794 0.230466C4.90789 0.110527 5.09211 0.110527 5.19206 0.230466Z"
      fill="currentColor"
    />
  </svg>
);
