import { ComponentProps } from 'react';

export const TinyCrystal = (props: ComponentProps<'svg'>) => (
  <svg
    width="172"
    height="200"
    viewBox="0 0 172 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_4_26)">
      <path d="M86.3526 72V104.247L72 99.1371L86.3526 72Z" fill="url(#paint0_linear_4_26)" />
      <path d="M86.3526 104.247L100 98.6332L86.3526 72V104.247Z" fill="url(#paint1_linear_4_26)" />
      <path d="M72 99.1371L86.3526 128V104.247L72 99.1371Z" fill="url(#paint2_linear_4_26)" />
      <path d="M100 98.6332L86.3526 128V104.247L100 98.6332Z" fill="url(#paint3_linear_4_26)" />
    </g>
    <path d="M86.2015 84V102.427L78 99.5069L86.2015 84Z" fill="url(#paint4_linear_4_26)" />
    <path d="M86.2015 102.427L94 99.219L86.2015 84V102.427Z" fill="url(#paint5_linear_4_26)" />
    <path d="M78 99.5069L86.2015 116V102.427L78 99.5069Z" fill="url(#paint6_linear_4_26)" />
    <path d="M94 99.219L86.2015 116V102.427L94 99.219Z" fill="url(#paint7_linear_4_26)" />
    <defs>
      <filter
        id="filter0_f_4_26"
        x="0.0999985"
        y="0.0999985"
        width="171.8"
        height="199.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="35.95" result="effect1_foregroundBlur_4_26" />
      </filter>
      <linearGradient
        id="paint0_linear_4_26"
        x1="86.3526"
        y1="88.1234"
        x2="72"
        y2="88.1234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_26"
        x1="93.1763"
        y1="72"
        x2="85.5801"
        y2="105.906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4_26"
        x1="79.1763"
        y1="99.1371"
        x2="79.1763"
        y2="128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4_26"
        x1="93.1763"
        y1="128"
        x2="93.1763"
        y2="98.6332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4_26"
        x1="86.2015"
        y1="93.2134"
        x2="78"
        y2="93.2134"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4_26"
        x1="90.1007"
        y1="84"
        x2="85.76"
        y2="103.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4_26"
        x1="82.1007"
        y1="99.5069"
        x2="82.1007"
        y2="116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4_26"
        x1="90.1007"
        y1="116"
        x2="90.1007"
        y2="99.219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
    </defs>
  </svg>
);
