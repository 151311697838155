import { ComponentProps } from 'react';

export const SuccessIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 85.75C69.2965 85.75 85.75 69.2965 85.75 49C85.75 28.7035 69.2965 12.25 49 12.25C28.7035 12.25 12.25 28.7035 12.25 49C12.25 69.2965 28.7035 85.75 49 85.75ZM48.3737 64.1308L68.7903 39.6308L61.8763 33.8692L44.6143 54.5836L35.8486 45.818L29.4847 52.182L41.7347 64.432L45.2191 67.9164L48.3737 64.1308Z"
      fill="#34B263"
    />
  </svg>
);
