import emailjs from '@emailjs/browser';
import { About } from 'pages/About';
import { Home } from 'pages/Home';
import { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PUBLIC_KEY } from 'shared/lib/constants/forms';
import { GA_KEY } from 'shared/lib/constants/global';
import { initGA } from 'shared/lib/utils/thirdPartyScripts/analytics';
import { PageTemplate } from 'shared/ui/templates/PageTemplate';
import './styles/global.css';
import './styles/reset.css';
import './styles/toast.css';
import './styles/typography.css';
import './styles/variables.css';

function App() {
  useEffect(() => {
    emailjs.init({
      publicKey: PUBLIC_KEY,
    });

    if (GA_KEY) {
      initGA(GA_KEY);
    }
  }, []);

  return (
    <PageTemplate>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </PageTemplate>
  );
}

export default App;
