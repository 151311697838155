import { ComponentProps } from 'react';

export const LogoutIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6665 2.49984C11.6665 2.0396 12.0396 1.6665 12.4998 1.6665H15.8332C16.4962 1.6665 17.1321 1.9299 17.6009 2.39874C18.0698 2.86758 18.3332 3.50346 18.3332 4.1665V15.8332C18.3332 16.4962 18.0698 17.1321 17.6009 17.6009C17.1321 18.0698 16.4962 18.3332 15.8332 18.3332H12.4998C12.0396 18.3332 11.6665 17.9601 11.6665 17.4998C11.6665 17.0396 12.0396 16.6665 12.4998 16.6665H15.8332C16.0542 16.6665 16.2661 16.5787 16.4224 16.4224C16.5787 16.2661 16.6665 16.0542 16.6665 15.8332V4.1665C16.6665 3.94549 16.5787 3.73353 16.4224 3.57725C16.2661 3.42097 16.0542 3.33317 15.8332 3.33317H12.4998C12.0396 3.33317 11.6665 2.96007 11.6665 2.49984Z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.74408 5.24408C8.06951 4.91864 8.59715 4.91864 8.92259 5.24408L13.0893 9.41074C13.4147 9.73618 13.4147 10.2638 13.0893 10.5893L8.92259 14.7559C8.59715 15.0814 8.06951 15.0814 7.74408 14.7559C7.41864 14.4305 7.41864 13.9028 7.74408 13.5774L11.3215 10L7.74408 6.42259C7.41864 6.09715 7.41864 5.56951 7.74408 5.24408Z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6665 9.99984C1.6665 9.5396 2.0396 9.1665 2.49984 9.1665H12.4998C12.9601 9.1665 13.3332 9.5396 13.3332 9.99984C13.3332 10.4601 12.9601 10.8332 12.4998 10.8332H2.49984C2.0396 10.8332 1.6665 10.4601 1.6665 9.99984Z"
      fill="#FAFAFA"
    />
  </svg>
);
