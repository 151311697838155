import cx from 'classnames';
import { useMediaQuery } from 'shared/lib/hooks/useMediaQuery';

import { Card } from './components/Card';
import { ComingSoonIcon } from './components/ComingSoonIcon';
import { HugeCrystal } from './components/HugeCrystal';
import { LargeCrystal } from './components/LargeCrystal';
import { MediumCrystal } from './components/MediumCrystal';
import { SmallCrystal } from './components/SmallCrystal';
import { TinyCrystal } from './components/TinyCrystal';
import css from './index.module.css';

const items: { title: string; description: string; bg: 'oye' | 'cys' | 'sr' }[] = [
  {
    title: 'Optimize Your Earnings',
    description:
      'Seamlessly provide liquidity to top pools and staking protocols to maximize point farming',
    bg: 'oye',
  },
  {
    title: 'Choose Your Strategy',
    description:
      'Dive into "Risk-Taking LRT" for more points, or opt for "Risk-Averse LRT" for a higher APY.',
    bg: 'cys',
  },
  {
    title: 'Smart Rebalancing',
    description:
      'Our advanced algorithms ensure optimal asset allocation and rebalancing to boost your profits.',
    bg: 'sr',
  },
];

export const LRT = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <section className={css.wrapper}>
      <ComingSoonIcon isSmall={isMobile} className={css.coming_soon} />
      <div className={cx('content', css.inner)}>
        <HugeCrystal className={css.huge} />
        <LargeCrystal className={css.large} />
        <MediumCrystal className={css.medium} />
        <SmallCrystal className={css.small} />
        <TinyCrystal className={css.tiny} />
        <h2 className={css.title}>Liquid restaking (LRT) vault</h2>
        <ul className={css.cards}>
          {items.map((item) => (
            <li key={item.title}>
              <Card className={css.card} {...item} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
