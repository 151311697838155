import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WC_PROJECT } from 'shared/lib/constants/global';
import { createStorage, deserialize, serialize } from 'wagmi';
import { mainnet } from 'wagmi/chains';

export const metadata = {
  name: 'LevelQ',
  description: 'Automated Wealth Management on DeFi',
  url: 'https://levelq.finance/',
  icons: [
    'https://levelq-finance.gitbook.io/~gitbook/image?url=https:%2F%2F2384625652-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FPMX4exnmm3avyBQWKOjD%252Fuploads%252F3J1xBbpbNhsz5fIESBvy%252FGroup%252017.png%3Falt=media%26token=aaf13b43-208f-4a3a-a4ba-fe6bd49d491e&width=768&dpr=2&quality=100&sign=3d35c0a695436fbcf1c54de7dcd96a7f2b19e77f7d60761dcfcf77024562bc6a',
  ],
};

const storage = createStorage({ key: 'levelq', storage: localStorage, serialize, deserialize });

export const config = defaultWagmiConfig({
  chains: [mainnet],
  projectId: WC_PROJECT,
  metadata,
  storage,
});
