import { ComponentProps } from 'react';

export const Discord = (props: ComponentProps<'svg'>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4144 16.375C15.4144 17.25 16.7269 19 17.1644 19C18.4769 19 19.6433 17.5414 20.2269 16.375C20.8106 14.9164 20.6644 11.2711 18.9144 6.3125C17.6396 5.42437 16.2894 5.14 14.9769 5L14.1264 6.68262C12.953 6.48006 11.7535 6.48006 10.5801 6.68262L9.72694 5C8.41445 5.14 7.06432 5.42437 5.78945 6.3125C4.03945 11.2711 3.89332 14.9164 4.47695 16.375C5.06057 17.5414 6.22695 19 7.53945 19C7.97695 19 9.28945 17.25 9.28945 16.375"
      fill="currentColor"
    />
    <path
      d="M8.74927 12C8.74927 12.2321 8.84416 12.4546 9.01307 12.6187C9.18197 12.7828 9.41106 12.875 9.64993 12.875C9.88881 12.875 10.1179 12.7828 10.2868 12.6187C10.4557 12.4546 10.5506 12.2321 10.5506 12C10.5506 11.7679 10.4557 11.5454 10.2868 11.3813C10.1179 11.2172 9.88881 11.125 9.64993 11.125C9.41106 11.125 9.18197 11.2172 9.01307 11.3813C8.84416 11.5454 8.74927 11.7679 8.74927 12Z"
      stroke="#212836"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1533 12C14.1533 12.2321 14.2482 12.4546 14.4171 12.6187C14.586 12.7828 14.8151 12.875 15.054 12.875C15.2929 12.875 15.5219 12.7828 15.6909 12.6187C15.8598 12.4546 15.9547 12.2321 15.9547 12C15.9547 11.7679 15.8598 11.5454 15.6909 11.3813C15.5219 11.2172 15.2929 11.125 15.054 11.125C14.8151 11.125 14.586 11.2172 14.4171 11.3813C14.2482 11.5454 14.1533 11.7679 14.1533 12Z"
      stroke="#212836"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84839 15.9375C11.0007 16.8125 13.7027 16.8125 16.8551 15.9375"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
