import css from './index.module.css';

export const Roadmap = () => (
  <section className={css.wrapper}>
    <div className="content">
      <h2 className={css.title}>LevelQ Roadmap</h2>
      <div className={css.picture_wrapper}>
        <picture>
          <source srcSet="/images/home/roadmap/roadmap_mob.png" media="(max-width: 768px)" />
          <img
            className={css.roadmap}
            src="/images/home/roadmap/roadmap.png"
            alt="Roadmap"
            loading="lazy"
          />
        </picture>
      </div>
    </div>
  </section>
);
