import cx from 'classnames';

import { Card } from './components/Card';
import css from './index.module.css';

const team = [
  {
    name: 'Philip Langfelder',
    position: 'Chief Executive Officer',
    image: `/images/about/team/philipp_l.png`,
    imageHighRes: `/images/about/team/philipp_l@2x.png`,
  },
  {
    name: 'Sachin Gurung',
    position: 'Chief Revenue Officer',
    image: `/images/about/team/sancnin_g.png`,
    imageHighRes: `/images/about/team/sancnin_g@2x.png`,
  },
  {
    name: 'Ivan Markov',
    position: 'Chief Product Officer',
    image: `/images/about/team/ivan_m.png`,
    imageHighRes: `/images/about/team/ivan_m@2x.png`,
  },
  {
    name: 'Vadim Filippov',
    position: 'Chief Technology Officer',
    image: `/images/about/team/vadim_f.png`,
    imageHighRes: `/images/about/team/vadim_f@2x.png`,
  },
  {
    name: 'Jerome Ostorero',
    position: 'Head of Research',
    image: `/images/about/team/jerome_o.png`,
    imageHighRes: `/images/about/team/jerome_o@2x.png`,
  },
  {
    name: 'Maxim Galash',
    position: 'Founding Advisor',
    image: `/images/about/team/max_g.png`,
    imageHighRes: `/images/about/team/max_g@2x.png`,
  },
];

export const TeamSection = () => (
  <section className={css.wrapper}>
    <div className="content">
      <h1 className={css.title}>Meet Our founders</h1>
      <p className={cx(css.description, 'title-medium-22')}>
        LevelQ founding team brings a wealth of experience from the realms of asset management and
        DeFi, composed of serial entrepreneurs, passionate blockchain enthusiasts, and experts in
        various fields, dedicated to innovating and pushing the boundaries of financial technology.
      </p>
      <ul className={css.team}>
        {team.map((el) => (
          <li key={el.name}>
            <Card {...el} />
          </li>
        ))}
      </ul>
    </div>
  </section>
);
