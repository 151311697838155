import { ComponentProps } from 'react';

export const HugeCrystal = (props: ComponentProps<'svg'>) => (
  <svg
    width="316"
    height="582"
    viewBox="0 0 316 582"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8" filter="url(#filter0_f_4_50)">
      <g filter="url(#filter1_f_4_50)">
        <path d="M158.554 247V297.673L136 289.644L158.554 247Z" fill="url(#paint0_linear_4_50)" />
        <path
          d="M158.554 297.673L180 288.852L158.554 247V297.673Z"
          fill="url(#paint1_linear_4_50)"
        />
        <path d="M136 289.644L158.554 335V297.673L136 289.644Z" fill="url(#paint2_linear_4_50)" />
        <path d="M180 288.852L158.554 335V297.673L180 288.852Z" fill="url(#paint3_linear_4_50)" />
      </g>
      <path d="M161.35 25V331.344L25 282.803L161.35 25Z" fill="url(#paint4_linear_4_50)" />
      <path d="M161.35 331.344L291 278.015L161.35 25V331.344Z" fill="url(#paint5_linear_4_50)" />
      <path d="M25 282.802L161.35 557V331.344L25 282.802Z" fill="url(#paint6_linear_4_50)" />
      <path d="M291 278.015L161.35 557V331.344L291 278.015Z" fill="url(#paint7_linear_4_50)" />
    </g>
    <defs>
      <filter
        id="filter0_f_4_50"
        x="0.799999"
        y="0.799999"
        width="314.4"
        height="580.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="12.1" result="effect1_foregroundBlur_4_50" />
      </filter>
      <filter
        id="filter1_f_4_50"
        x="64.1"
        y="175.1"
        width="187.8"
        height="231.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="35.95" result="effect1_foregroundBlur_4_50" />
      </filter>
      <linearGradient
        id="paint0_linear_4_50"
        x1="158.554"
        y1="272.337"
        x2="136"
        y2="272.337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_50"
        x1="169.277"
        y1="247"
        x2="157.34"
        y2="300.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4_50"
        x1="147.277"
        y1="289.644"
        x2="147.277"
        y2="335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4_50"
        x1="169.277"
        y1="335"
        x2="169.277"
        y2="288.852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4_50"
        x1="161.35"
        y1="178.172"
        x2="25"
        y2="178.172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4_50"
        x1="226.175"
        y1="25"
        x2="154.011"
        y2="347.108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4_50"
        x1="93.1749"
        y1="282.802"
        x2="93.1749"
        y2="557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4_50"
        x1="226.175"
        y1="557"
        x2="226.175"
        y2="278.015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
    </defs>
  </svg>
);
