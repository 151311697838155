import { ComponentProps } from 'react';

export const YieldFarming = (props: ComponentProps<'svg'>) => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2087_1672)">
      <rect y="0.5" width="80" height="80" rx="15" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2087_1672)">
        <circle cx="71" cy="74.5" r="19" fill="#00E35B" />
      </g>
      <path
        d="M58.438 55.4718L36.318 33.3562L37.3262 32.3475C37.4945 32.1788 37.6171 31.97 37.6823 31.7408C37.7476 31.5116 37.7535 31.2695 37.6993 31.0375C43.5799 26.275 49.6837 23.5637 54.2268 23.5462C49.5518 20.7931 41.8249 22.53 34.7437 27.775L34.3393 27.3706C34.0748 27.1068 33.7166 26.9586 33.343 26.9586C32.9695 26.9586 32.6112 27.1068 32.3468 27.3706L31.3387 28.3781L29.158 26.1987C28.9393 25.9812 28.6637 25.8868 28.3587 25.8868C26.633 25.8868 23.9568 28.9212 25.1937 30.1462L27.3818 32.3337L26.3705 33.345C26.1079 33.6098 25.9605 33.9676 25.9605 34.3406C25.9605 34.7136 26.1079 35.0714 26.3705 35.3362L26.7762 35.7412C21.5299 42.8193 19.7924 50.5443 22.5462 55.2169C22.5655 50.6756 25.2768 44.5731 30.0393 38.695C30.2712 38.7487 30.513 38.7427 30.742 38.6776C30.971 38.6124 31.1797 38.4902 31.3487 38.3225L32.3605 37.3118L54.488 59.4343C54.7055 59.6543 54.9805 59.75 55.2855 59.75C56.9999 59.75 59.6637 56.7068 58.438 55.4718Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2087_1672"
        x="-28.1"
        y="-24.6"
        width="198.2"
        height="198.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="40.05" result="effect1_foregroundBlur_2087_1672" />
      </filter>
      <clipPath id="clip0_2087_1672">
        <rect y="0.5" width="80" height="80" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
