import { ComponentProps } from 'react';

export const RWAssets = (props: ComponentProps<'svg'>) => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2087_1673)">
      <rect y="0.5" width="80" height="80" rx="15" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2087_1673)">
        <circle cx="71" cy="74.5" r="19" fill="#009FE3" />
      </g>
      <path
        d="M22 37H30V51H22V37ZM36 31H44V57H36V31Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M40 61V57"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 29H58V38H50V29Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M26 37V27M54 51V38M54 29V21"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2087_1673"
        x="-28.1"
        y="-24.6"
        width="198.2"
        height="198.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="40.05" result="effect1_foregroundBlur_2087_1673" />
      </filter>
      <clipPath id="clip0_2087_1673">
        <rect y="0.5" width="80" height="80" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
