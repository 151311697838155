import { ComponentProps } from 'react';

export const BurgerCloseIcon = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9426 7.05703C25.4633 7.57773 25.4633 8.42195 24.9426 8.94265L8.94265 24.9426C8.42195 25.4633 7.57773 25.4633 7.05703 24.9426C6.53633 24.4219 6.53633 23.5777 7.05703 23.057L23.057 7.05703C23.5777 6.53633 24.4219 6.53633 24.9426 7.05703Z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05703 7.05703C7.57773 6.53633 8.42195 6.53633 8.94265 7.05703L24.9426 23.057C25.4633 23.5777 25.4633 24.4219 24.9426 24.9426C24.4219 25.4633 23.5777 25.4633 23.057 24.9426L7.05703 8.94265C6.53633 8.42195 6.53633 7.57773 7.05703 7.05703Z"
      fill="#FAFAFA"
    />
  </svg>
);
