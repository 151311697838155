import emailjs from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SERVICE_ID, WHITELIST_TEMPLATE } from 'shared/lib/constants/forms';
import { useMediaQuery } from 'shared/lib/hooks/useMediaQuery';
import { Button } from 'shared/ui/molecules/Button';
import { Input } from 'shared/ui/molecules/Input';
import { WhitelistModal } from 'shared/ui/molecules/modals/WhitelistModal';

import css from './index.module.css';
import { validationScheme } from './validation';

type FormFields = {
  user_name: string;
  user_email: string;
};

export const Form = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormFields>({
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: FormFields) => {
    try {
      setIsSending(true);
      await emailjs.send(SERVICE_ID, WHITELIST_TEMPLATE, data);
      setIsOpen(true);
      reset();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={!!errors.user_name?.message}
        placeholder="Your Name"
        name="user_name"
        type="text"
        fieldSize={isMobile ? 's' : 'l'}
        RHFRegister={register('user_name')}
      />
      <Input
        error={!!errors.user_email?.message}
        placeholder="Your Email"
        type="email"
        RHFRegister={register('user_email')}
        fieldSize={isMobile ? 's' : 'l'}
      />
      <Button disabled={isSending || !isValid} size="l" type="submit">
        Join Waitlist
      </Button>
      <WhitelistModal isSuccess isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </form>
  );
};
