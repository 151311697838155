import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { FOOTER_LINKS } from 'shared/lib/constants/links';
import { LogoIcon } from 'shared/ui/atoms/icons/LogoIcon';
import { Socials } from 'shared/ui/molecules/Socials';

import { Form } from './components/Form';
import css from './index.module.css';

export const Footer = () => (
  <footer className={css.wrapper}>
    <div className="content">
      <div className={css.head}>
        <div className={css.logo}>
          <LogoIcon />
          <a
            className={css.email}
            href="mailto:info@levelq.finance"
            target="_blank"
            rel="noreferrer"
          >
            info@levelq.finance
          </a>
        </div>
        <div className={css.info}>
          <nav className={css.info_nav}>
            <ul className={css.info_links}>
              {FOOTER_LINKS.map(({ title, href, to }) => (
                <li key={title}>
                  {href ? (
                    <a
                      className={cx('text-regular-16', css.info_link)}
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  ) : (
                    <NavLink className={cx('text-regular-16', css.info_link)} to={to!}>
                      {title}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <Socials className={css.socials} />
        </div>
      </div>
    </div>
    <div className={css.contact}>
      <picture>
        <source srcSet="/images/common/footer/contact_mob.png" media="(max-width: 1024px)" />
        <img
          src="/images/common/footer/contact.png"
          alt="Contact"
          loading="lazy"
          width={1380}
          height={962}
          className={css.contact_bg}
        />
      </picture>

      <div className="content">
        <div className={css.contact_inner}>
          <h2 className={css.contact_title}>Leave us a message, and we will get back to you!</h2>
          <Form onSuccess={() => null} />
        </div>
      </div>
    </div>
    <div className="content">
      <div className={css.copyright}>
        <p className="text-regular-12">
          The content on this site is intended solely for informational use and does not constitute
          legal, tax, investment, financial, or any type of professional advice. Please refrain from
          taking any action based on the content available here or any additional materials we
          provide, such as blog posts, datasets, articles, external content links, news feeds,
          tutorials, tweets, and video tutorials. Before making important decisions regarding
          financial, legal, technical, or other areas, it is recommended to consult with an
          independent professional who is licensed and qualified in the relevant field. The
          information here does not cover all aspects of LevelQ or its products.
          <br />
          You are not permitted to purchase or acquire any of our token products if you are a U.S.
          Person (which includes citizens, residents, green card holders, entities incorporated or
          controlled by U.S. Persons, located in, or operating from the U.S.), or a person from any
          jurisdiction where the purchase or sale of our tokens is prohibited ("Restricted Person").
          The term "Restricted Person" also includes any individual or organization established or
          operating under the laws of a jurisdiction where our tokens cannot be legally traded. You
          must not resell or transfer our token products to any Restricted Person, and any such
          transfer or resale is strictly prohibited. Make sure to read and follow our Terms of
          Service while using our website.
        </p>
        <p className="text-regular-12">© 2024 LevelQ. All rights reserved”</p>
      </div>
    </div>
  </footer>
);
