import cx from 'classnames';

import { CrossIcon } from '../../icons/CrossIcon';
import { SuccessIcon } from '../../icons/toasts/SuccessIcon';

import css from './index.module.css';

type Props = {
  message: string;
  type?: 'success';
  closeToast?: () => void;
  showCloseBtn?: boolean;
  className?: string;
};

export const Toast = ({
  message,
  closeToast,
  type = 'success',
  showCloseBtn = true,
  className,
}: Props) => (
  <div className={cx(css.wrapper, css[type], className || '')}>
    {type === 'success' && <SuccessIcon className={css.icon} />}
    <p className="title-medium-16">{message}</p>
    {showCloseBtn && (
      <button className={css.close} aria-label="close" type="button" onClick={closeToast}>
        <CrossIcon />
      </button>
    )}
  </div>
);
