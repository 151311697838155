import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  title: string;
  description: string;
  bg: 'oye' | 'cys' | 'sr';
} & ComponentProps<'div'>;

export const Card = ({ title, description, className, bg, ...props }: Props) => (
  <div className={cx(css.card, className || '', css[bg])} {...props}>
    <h4 className={css.title}>{title}</h4>
    <p className={cx('text-regular-16', css.description)}>{description}</p>
  </div>
);
