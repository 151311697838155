import { PartnersSection } from './components/PartnersSection';
import { TeamSection } from './components/TeamSection';
import css from './index.module.css';

export const About = () => (
  <>
    <img src="/images/about/bg.svg" alt="Background" loading="lazy" className={css.bg} />
    <TeamSection />
    <PartnersSection />
  </>
);
