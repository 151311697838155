import cx from 'classnames';

import { Button } from '../../Button';
import { BaseModal } from '../BaseModal';

import css from './index.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  disconnect: () => void;
};

export const DisconnectModal = ({ isOpen, onClose, disconnect }: Props) => (
  <BaseModal isOpen={isOpen} onClose={onClose}>
    <h2 className={cx(css.title, 'title-bold-32')}>
      Are you sure you want to log out from your crypto wallet?
    </h2>
    <p className={cx(css.text, 'text-regular-18')}>
      Before you proceed, please ensure that you are logging out from a secure and trusted device.
      Avoid logging out from public or shared devices to prevent unauthorized access to your wallet.
    </p>
    <Button
      className={css.logout}
      type="button"
      onClick={() => {
        disconnect();
        onClose();
      }}
    >
      Log Out
    </Button>
  </BaseModal>
);
