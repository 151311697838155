import { useWeb3Modal, useWeb3ModalEvents } from '@web3modal/wagmi/react';
import { useEffect } from 'react';
import Storage from 'shared/lib/utils/storage';
import { sendGAEvent } from 'shared/lib/utils/thirdPartyScripts/analytics';
import { useAccount, useConnect, useDisconnect } from 'wagmi';

type HookReturnType = {
  address: `0x${string}` | undefined;
  connectWallet: () => void;
  disconnect: () => void;
  isLoading: boolean;
  error: boolean;
  isConnected: boolean;
};

export const useWallet = (): HookReturnType => {
  const { open } = useWeb3Modal();
  const { address, isConnected, isConnecting, isReconnecting } = useAccount();
  const { disconnect } = useDisconnect();
  const { error: connectError } = useConnect();
  const {
    data: { event },
  } = useWeb3ModalEvents();

  useEffect(() => {
    if (event === 'CONNECT_SUCCESS') {
      window.location.reload();
    }
  }, [event]);

  useEffect(() => {
    const isAnalyticsSended = Storage.get('saved_address');
    if (isConnected && address && isAnalyticsSended !== address) {
      sendGAEvent('Wallet Connected', {
        category: 'Wallet',
        action: 'wallet_connected',
        address: `${address} ${new Date().toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}`,
      });
      Storage.set('saved_address', address);
    }
  }, [address, isConnected]);

  const connectWallet = () => open();

  const onDisconnect = () => {
    disconnect();
    Storage.remove('saved_address');
  };

  return {
    address,
    connectWallet,
    disconnect: onDisconnect,
    isLoading: isConnecting || isReconnecting,
    error: !!connectError,
    isConnected,
  };
};
