import { ComponentProps } from 'react';

export const LargeCrystal = (props: ComponentProps<'svg'>) => (
  <svg
    width="188"
    height="232"
    viewBox="0 0 188 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_4_14)">
      <path d="M94.5541 72V122.673L72 114.644L94.5541 72Z" fill="url(#paint0_linear_4_14)" />
      <path d="M94.5541 122.673L116 113.852L94.5541 72V122.673Z" fill="url(#paint1_linear_4_14)" />
      <path d="M72 114.644L94.5541 160V122.673L72 114.644Z" fill="url(#paint2_linear_4_14)" />
      <path d="M116 113.852L94.5541 160V122.673L116 113.852Z" fill="url(#paint3_linear_4_14)" />
    </g>
    <path d="M94.7304 58V124.221L65 113.728L94.7304 58Z" fill="url(#paint4_linear_4_14)" />
    <path d="M94.7304 124.221L123 112.693L94.7304 58V124.221Z" fill="url(#paint5_linear_4_14)" />
    <path d="M65 113.728L94.7304 173V124.221L65 113.728Z" fill="url(#paint6_linear_4_14)" />
    <path d="M123 112.693L94.7304 173V124.221L123 112.693Z" fill="url(#paint7_linear_4_14)" />
    <defs>
      <filter
        id="filter0_f_4_14"
        x="0.0999985"
        y="0.0999985"
        width="187.8"
        height="231.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="35.95" result="effect1_foregroundBlur_4_14" />
      </filter>
      <linearGradient
        id="paint0_linear_4_14"
        x1="94.5541"
        y1="97.3367"
        x2="72"
        y2="97.3367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_14"
        x1="105.277"
        y1="72"
        x2="93.3401"
        y2="125.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4_14"
        x1="83.277"
        y1="114.644"
        x2="83.277"
        y2="160"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4_14"
        x1="105.277"
        y1="160"
        x2="105.277"
        y2="113.852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4_14"
        x1="94.7304"
        y1="91.1105"
        x2="65"
        y2="91.1105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DE9" />
        <stop offset="1" stopColor="#2E235A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4_14"
        x1="108.865"
        y1="58"
        x2="93.3876"
        y2="127.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4_14"
        x1="79.8652"
        y1="113.728"
        x2="79.8652"
        y2="173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#374784" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4_14"
        x1="108.865"
        y1="173"
        x2="108.865"
        y2="112.693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#617DEA" />
        <stop offset="1" stopColor="#233166" />
      </linearGradient>
    </defs>
  </svg>
);
