import cx from 'classnames';

import css from './index.module.css';

type Props = {
  title: string;
  description: string;
  bg: 'type' | 'total-supply' | 'initial-release' | 'tge';
};

export const Card = ({ title, description, bg }: Props) => (
  <div className={cx(css.wrapper, css[bg])}>
    <span className={css.title}>{title}</span>
    <h5 className={cx(css.description, 'title-medium-18')}>{description}</h5>
  </div>
);
