import { Decoded } from './components/Decoded';
import { HeroSection } from './components/HeroSection';
import { HowItWorks } from './components/HowItWorks';
import { LRT } from './components/LRT';
import { PartnersSection } from './components/PartnersSection';
import { Roadmap } from './components/Roadmap';
import { TokenSection } from './components/TokenSection';
import { Vaults } from './components/Vaults';
import { WhitelistSection } from './components/WhitelistSection';

export const Home = () => (
  <>
    <HeroSection />
    <PartnersSection />
    <LRT />
    <Decoded />
    <HowItWorks />
    <Vaults />
    <TokenSection />
    <Roadmap />
    <WhitelistSection />
  </>
);
