/* eslint-disable jsx-a11y/control-has-associated-label */
import cx from 'classnames';
import { ComponentProps, useState } from 'react';
import { NAV_ITEMS } from 'shared/lib/constants/links';
import useOutsideClick from 'shared/lib/hooks/useOutsideClick';
import { truncateStr } from 'shared/lib/utils/helpers/truncateStr';
import { ArrowDropDown } from 'shared/ui/atoms/icons/ArrowDropDown';
import { CopyIcon } from 'shared/ui/atoms/icons/CopyIcon';
import { LogoutIcon } from 'shared/ui/atoms/icons/LogoutIcon';
import { Button } from 'shared/ui/molecules/Button';
import { Socials } from 'shared/ui/molecules/Socials';

import { MobileLink } from './components/MobileLink';
import css from './index.module.css';

type Props = {
  onClose: () => void;
  onWLBtnClick: () => void;
  onWalletBtnClick: () => void;
  address?: `0x${string}`;
  onLogOutClick: () => void;
  handleCopyClick: () => void;
} & ComponentProps<'div'>;

export const MobileMenu = ({
  onClose,
  onWLBtnClick,
  onWalletBtnClick,
  address,
  onLogOutClick,
  handleCopyClick,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useOutsideClick<HTMLButtonElement>(() => setIsOpen(false), isOpen);

  return (
    <div className={css.wrapper} {...props}>
      <nav>
        <ul className={css.links}>
          {NAV_ITEMS.filter((el) => el.href || el.to).map((el) => (
            <li key={el.title}>
              <MobileLink {...el} target={el.href ? '_blank' : undefined} onClick={onClose} />
            </li>
          ))}
        </ul>
      </nav>
      <div className={css.menu_wrapper}>
        <button
          className={cx(css.menu, 'title-medium-16', {
            [css.menu__open]: isOpen,
          })}
          ref={menuRef}
          onClick={() => setIsOpen(!isOpen)}
        >
          Social Media
          <ArrowDropDown
            className={cx(css.arrow, {
              [css.arrow__open]: isOpen,
            })}
          />
        </button>
        {isOpen && <Socials className={css.socials} />}
      </div>
      <div className={css.footer}>
        {!address && (
          <Button className={css.connect} onClick={onWalletBtnClick}>
            Connect Wallet
          </Button>
        )}
        <Button variant="secondary" className={css.whitelist} onClick={onWLBtnClick}>
          Join Waitlist
        </Button>
        {address && (
          <div className={css.wallet}>
            <Button onClick={handleCopyClick} className={css.address}>
              {truncateStr(address, 6, 5)}
              <CopyIcon className={css.copy_icon} />
            </Button>
            <button
              onClick={onLogOutClick}
              className={cx(css.logout, 'title-medium-14')}
              aria-label="logout"
            >
              Logout
              <LogoutIcon />
            </button>
            <input
              style={{
                display: 'none',
              }}
              type="text"
              value={address}
              readOnly
            />
          </div>
        )}
      </div>
    </div>
  );
};
