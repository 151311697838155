import css from './index.module.css';

export const HowItWorks = () => (
  <section className={css.wrapper}>
    <div className="content">
      <h2 className={css.title}>HOW IT WORKS</h2>
      <div className={css.picture_wrapper}>
        <picture>
          <source
            srcSet="/images/home/howItWorks/scheme_mobile.png 1x, /images/home/howItWorks/scheme_mobile@2x.png 2x"
            media="(max-width: 768px)"
          />
          <img
            src="/images/home/howItWorks/scheme.png"
            alt="Scheme"
            loading="lazy"
            srcSet="/images/home/howItWorks/scheme.png 1x, /images/home/howItWorks/scheme@2x.png 2x"
            className={css.picture}
          />
        </picture>
      </div>
    </div>
  </section>
);
