import cx from 'classnames';
import Marquee from 'react-fast-marquee';

import css from './index.module.css';

const items = [
  {
    logo: '/images/common/partners/houdini.png',
    link: 'https://houdiniswap.com/',
  },
  {
    logo: '/images/common/partners/cake-wallet.png',
    link: 'https://cakewallet.com/',
  },
  {
    logo: '/images/common/partners/ventures.png',
    link: 'https://g1.vc/',
  },
  {
    logo: '/images/common/partners/node.png',
    link: 'https://nodereal.io/',
  },
  {
    logo: '/images/common/partners/layer-zero.png',
    link: 'https://layerzero.network/',
  },
  {
    logo: '/images/common/partners/idle.png',
    link: 'https://idle.finance/)',
  },
  {
    logo: '/images/common/partners/velvet-capital.png',
    link: 'https://www.velvet.capital/',
  },
  {
    logo: '/images/common/partners/levitate-labs.png',
    link: 'https://www.levitatelabs.xyz/',
  },
  {
    logo: '/images/common/partners/hacken.png',
    link: 'https://hacken.io/',
  },
  {
    logo: '/images/common/partners/coinchange.png',
    link: 'https://www.coinchange.io/',
  },
  {
    logo: '/images/common/partners/bde.png',
    link: 'https://bde.io/',
  },
];

type Props = {
  className?: string;
};

export const PartnersSection = ({ className }: Props) => (
  <section className={css.wrapper}>
    <Marquee autoFill speed={100} pauseOnHover className={cx(css.marquee, className)}>
      {items.map(({ logo, link }) => (
        <a className={css.item} href={link} target="_blank" key={link} rel="noreferrer">
          <img className={css.logo} src={logo} alt="Logo" loading="lazy" height={32} />
        </a>
      ))}
    </Marquee>
  </section>
);
