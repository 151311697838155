import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

export type Props = {
  title: string;
  text: string;
  bg: 'connect' | 'collect' | 'select';
} & ComponentProps<'div'>;

export const Card = ({ title, text, className, bg, ...props }: Props) => (
  <div className={cx(css.wrapper, className || '', css[bg])} {...props}>
    <h5 className={css.title}>{title}</h5>
    <p className={cx(css.text, 'text-regular-16')}>{text}</p>
  </div>
);
