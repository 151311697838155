import { ReactNode } from 'react';
import { Footer } from 'widgets/Footer';
import { Header } from 'widgets/Header';

import css from './index.module.css';

type Props = {
  children: ReactNode;
};

export const PageTemplate = ({ children }: Props) => (
  <div className={css.wrapper}>
    <Header />
    <main className={css.container}>{children}</main>
    <Footer />
  </div>
);
