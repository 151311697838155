import cx from 'classnames';
import { HTMLAttributeAnchorTarget } from 'react';
import { NavLink, To } from 'react-router-dom';

import css from './index.module.css';

type Props = {
  to?: To;
  href?: string;
  title: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const MobileLink = ({ to, href, title, target, className, disabled, onClick }: Props) => {
  if (to) {
    return (
      <NavLink
        className={cx(css.link, 'title-medium-16', className, {
          [css.link__disabled]: disabled,
        })}
        target={target}
        to={to}
        onClick={onClick}
      >
        {title}
      </NavLink>
    );
  }

  return (
    <a
      className={cx(css.link, 'title-medium-16', className, {
        [css.link__disabled]: disabled,
      })}
      target={target}
      href={href}
      onClick={onClick}
    >
      {title}
    </a>
  );
};
