class Storage {
  static set(key: string, value: {} | string) {
    const val = typeof value === 'object' ? JSON.stringify(value) : String(value);
    localStorage.setItem(key, val);
  }

  static get(key: string): string | null {
    const result = localStorage.getItem(key);
    return result === undefined || result === null ? null : result;
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }
}

export default Storage;
