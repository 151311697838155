import cx from 'classnames';

import { Card } from './components/Card';
import { LiquidRestaking } from './components/icons/LiquidRestaking';
import { RWAssets } from './components/icons/RWAssets';
import { TokenIndices } from './components/icons/TokenIndices';
import { YieldFarming } from './components/icons/YieldFarming';
import css from './index.module.css';

const items = [
  {
    title: 'Liquid restaking',
    description: 'Reinvestment of Liquid Staking Tokens (LSTs)',
    icon: <LiquidRestaking />,
    link: 'https://docs.levelq.finance/use-cases/vault-use-cases#liquid-restaking-lrt',
  },
  {
    title: 'Yield farming',
    description: 'Automated strategies for maximizing DeFi returns',
    icon: <YieldFarming />,
    link: 'https://docs.levelq.finance/use-cases/vault-use-cases#yield-farming',
  },
  {
    title: 'Token indices',
    description: 'Diversified crypto investments through thematic portfolios',
    icon: <TokenIndices />,
    link: 'https://docs.levelq.finance/use-cases/vault-use-cases#yield-farming',
  },
  {
    title: 'Real-world assets',
    description: 'Access traditional markets via tokenized assets',
    icon: <RWAssets />,
    link: 'https://docs.levelq.finance/use-cases/vault-use-cases#yield-farming',
  },
];

export const Vaults = () => (
  <section className={css.wrapper}>
    <div className={cx('content', css.inner)}>
      <img
        src="/images/home/vaults/circle.png"
        alt="Shape"
        width={697}
        height={697}
        loading="lazy"
        className={css.circle}
      />
      <h2 className={css.title}>The vaults</h2>
      <p className={cx('title-medium-22', css.text)}>
        LevelQ offers a diverse range of products covering the largest DeFi opportunities
      </p>
      <div className={css.info}>
        <picture>
          <source srcSet="/images/home/vaults/logo_mob.png" media="(max-width: 768px)" />
          <img
            src="/images/home/vaults/logo.png"
            alt="Logo"
            width={546}
            height={558}
            loading="lazy"
            className={css.logo}
          />
        </picture>
        <ul className={css.list}>
          {items.map((item) => (
            <li key={item.title}>
              <Card {...item} />
            </li>
          ))}
        </ul>
      </div>
      <img
        src="/images/home/vaults/bubbles.png"
        alt="Shape"
        width={182}
        height={182}
        loading="lazy"
        className={css.bubbles}
      />
    </div>
  </section>
);
