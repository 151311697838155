import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  RHFRegister?: any;
  label?: string;
  error?: boolean;
  fieldSize?: 's' | 'l';
} & ComponentProps<'input'>;

export const Input = ({
  RHFRegister,
  label,
  type = 'text',
  className,
  placeholder = '',
  error,
  fieldSize = 's',
  ...props
}: Props) => (
  <div className={cx(css.wrapper)}>
    {label && <label className={cx(css.label, 'body')}>{label}</label>}
    <input
      placeholder={placeholder}
      className={cx(css.field, className, {
        [css.field__error]: error,
        [css.small]: fieldSize === 's',
        [css.large]: fieldSize === 'l',
      })}
      type={type}
      {...RHFRegister}
      {...props}
    />
  </div>
);
