import cx from 'classnames';
import { NavigationItem } from 'entities/types/common';
import { HTMLAttributeAnchorTarget, useState } from 'react';
import { NavLink, To } from 'react-router-dom';
import useOutsideClick from 'shared/lib/hooks/useOutsideClick';
import { ArrowDropDown } from 'shared/ui/atoms/icons/ArrowDropDown';

import { Submenu } from '../Submenu';

import css from './index.module.css';

type Props = {
  to?: To;
  href?: string;
  submenu?: NavigationItem[];
  title: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  disabled?: boolean;
  selected?: boolean;
};

export const NavItem = ({
  to,
  href,
  title,
  submenu,
  target = '_blank',
  className,
  disabled,
  selected,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useOutsideClick<HTMLButtonElement>(() => setIsOpen(false), isOpen);

  if (to) {
    return (
      <NavLink
        className={cx(css.link, 'title-medium-16', className, {
          [css.link__disabled]: disabled,
          [css.link__selected]: selected,
        })}
        to={to}
      >
        {title}
      </NavLink>
    );
  }

  if (href) {
    return (
      <a
        className={cx(css.link, 'title-medium-16', className, {
          [css.link__disabled]: disabled,
        })}
        target={target}
        href={href}
      >
        {title}
      </a>
    );
  }

  return (
    <button
      className={cx(css.link, css.menu, 'title-medium-16', {
        [css.menu__open]: isOpen,
      })}
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
    >
      {title}
      <ArrowDropDown
        className={cx(css.arrow, {
          [css.arrow__open]: isOpen,
        })}
      />
      <Submenu isOpen={isOpen} setIsOpen={setIsOpen} items={submenu || []} />
    </button>
  );
};
