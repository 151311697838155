import { ComponentProps } from 'react';

export const VotingPower = (props: ComponentProps<'svg'>) => (
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2090_2048)">
      <rect y="0.5" width="54" height="54" rx="10" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2090_2048)">
        <circle cx="44.5" cy="48" r="9.5" fill="#ADFF00" />
      </g>
      <path
        d="M25.6002 29.3L32.2002 22.7L30.3002 20.8L25.6002 25.5L23.7335 23.6334L21.8335 25.5334L25.6002 29.3ZM27.0002 40.8334C23.4224 37.7889 20.7504 34.9614 18.9842 32.3507C17.2179 29.74 16.3344 27.3231 16.3335 25.1C16.3335 21.7667 17.4059 19.1111 19.5508 17.1334C21.6957 15.1556 24.1788 14.1667 27.0002 14.1667C29.8215 14.1667 32.3051 15.1556 34.4508 17.1334C36.5966 19.1111 37.6686 21.7667 37.6668 25.1C37.6668 27.3222 36.7837 29.7391 35.0175 32.3507C33.2513 34.9622 30.5788 37.7898 27.0002 40.8334Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2090_2048"
        x="-4.7"
        y="-1.2"
        width="98.4"
        height="98.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.85" result="effect1_foregroundBlur_2090_2048" />
      </filter>
      <clipPath id="clip0_2090_2048">
        <rect y="0.5" width="54" height="54" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
