import cx from 'classnames';
import { useWallet } from 'entities/wallet/hooks/useWallet';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'shared/lib/hooks/useMediaQuery';
import { copyToClipboard } from 'shared/lib/utils/helpers/copyToClipboard';
import { BurgerCloseIcon } from 'shared/ui/atoms/icons/BurgerCloseIcon';
import { BurgerIcon } from 'shared/ui/atoms/icons/BurgerIcon';
import { LogoIcon } from 'shared/ui/atoms/icons/LogoIcon';
import { toaster } from 'shared/ui/atoms/toasts';
import { DisconnectModal } from 'shared/ui/molecules/modals/DisconnectModal';
import { WhitelistModal } from 'shared/ui/molecules/modals/WhitelistModal';

import { MobileMenu } from './components/MobileMenu';
import { Navbar } from './components/Navbar';
import css from './index.module.css';

export const Header = () => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenWL, setIsOpenWL] = useState<boolean>(false);
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState<boolean>(false);
  const { disconnect, address, connectWallet } = useWallet();

  const handleCopyClick = () => {
    copyToClipboard(address as string).then(() => {
      toaster.success({
        message: 'Address Copied!',
      });
    });
  };

  return (
    <>
      <header className={css.wrapper}>
        <div className={cx('content', css.container)}>
          <div className={css.inner}>
            <NavLink to="/">
              <LogoIcon />
            </NavLink>
            {!isMobile ? (
              <Navbar
                onWLBtnClick={() => setIsOpenWL(true)}
                onWalletBtnClick={connectWallet}
                address={address}
                onLogoutClick={() => setIsOpenDisconnectModal(true)}
                handleCopyClick={handleCopyClick}
              />
            ) : (
              <button className={css.btn} type="button" onClick={() => setIsOpenMenu(!isOpenMenu)}>
                {!isOpenMenu ? <BurgerIcon /> : <BurgerCloseIcon />}
              </button>
            )}
          </div>
        </div>
        <WhitelistModal isOpen={isOpenWL} onClose={() => setIsOpenWL(false)} />
        <DisconnectModal
          isOpen={isOpenDisconnectModal}
          onClose={() => setIsOpenDisconnectModal(false)}
          disconnect={disconnect}
        />
      </header>
      {isMobile && isOpenMenu && (
        <MobileMenu
          onWLBtnClick={() => setIsOpenWL(true)}
          onWalletBtnClick={connectWallet}
          address={address}
          onLogOutClick={() => setIsOpenDisconnectModal(true)}
          handleCopyClick={handleCopyClick}
          onClose={() => setIsOpenMenu(false)}
        />
      )}
    </>
  );
};
