import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  RHFRegister?: any;
  label?: string;
  error: boolean;
  wrapperClassName?: string;
} & ComponentProps<'input'>;

export const Textarea = ({
  RHFRegister,
  label,
  type = 'text',
  className,
  placeholder = '',
  wrapperClassName,
  error,
  ...props
}: Props) => (
  <div className={cx(css.wrapper, wrapperClassName || '')}>
    <textarea
      placeholder={placeholder}
      className={cx(css.field, className, {
        [css.field__error]: error,
      })}
      {...RHFRegister}
      {...props}
      rows={4}
    />
    {label && <label className={cx(css.label, 'body')}>{label}</label>}
  </div>
);
