import cx from 'classnames';
import { useWallet } from 'entities/wallet/hooks/useWallet';
import { useState } from 'react';
import { Toast } from 'shared/ui/atoms/toasts/Toast';
import { Button } from 'shared/ui/molecules/Button';
import { WhitelistModal } from 'shared/ui/molecules/modals/WhitelistModal';

import { BigSphere } from './components/BigSphere';
import { HugeSphere } from './components/HugeSphere';
import { MiddleSphere } from './components/MiddleSphere';
import { SmallSphere } from './components/SmallSphere';
import css from './index.module.css';

export const HeroSection = () => {
  const [isOpenWL, setIsOpenWL] = useState<boolean>(false);
  const { address, connectWallet } = useWallet();

  return (
    <section className={css.wrapper}>
      <div className={cx('content', css.inner)}>
        <HugeSphere className={css.huge} />
        <BigSphere className={css.big} />
        <MiddleSphere className={css.middle} />
        <SmallSphere className={css.small} />
        <h1 className={cx(css.title, 'title-extrabold-96')}>
          <span className={cx('title-extrabold-96', css.title__gradient)}>Level up your</span> DeFi{' '}
          <span className={cx('title-extrabold-96', css.title__gradient)}>game</span>
        </h1>
        <h3 className={cx('title-medium-28', css.subtitle)}>The gateway to DeFi</h3>
        <div className={css.info}>
          {!address && (
            <>
              <Button size="l" className={css.info_action} onClick={connectWallet}>
                Connect Wallet
              </Button>
              <h5 className={cx(css.info_title, 'text-regular-14')}>Start Earning Points</h5>
            </>
          )}
          {address && (
            <Toast
              className={css.connected}
              message="You are farming points"
              showCloseBtn={false}
            />
          )}
        </div>
      </div>
      <WhitelistModal isOpen={isOpenWL} onClose={() => setIsOpenWL(false)} />
    </section>
  );
};
