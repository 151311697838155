import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  variant?: 'primary' | 'secondary';
  size?: 'm' | 's' | 'l';
} & ComponentProps<'button'>;

export const Button = ({
  variant = 'primary',
  size = 'm',
  className,
  children,
  type = 'button',
  ...props
}: Props) => (
  <button
    className={cx(css.btn, className, {
      [css.primary]: variant === 'primary',
      [css.secondary]: variant === 'secondary',
      [css.middle]: size === 'm',
      [css.small]: size === 's',
      [css.large]: size === 'l',
      'title-medium-16': size === 'm' || size === 'l',
      'text-regular-14': size === 's',
    })}
    type={type}
    {...props}
  >
    {children}
  </button>
);
