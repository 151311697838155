import { ComponentProps } from 'react';

export const BoostedYield = (props: ComponentProps<'svg'>) => (
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2090_2035)">
      <rect y="0.5" width="54" height="54" rx="10" fill="white" fillOpacity="0.02" />
      <g filter="url(#filter0_f_2090_2035)">
        <circle cx="44.5" cy="48" r="9.5" fill="#E30000" />
      </g>
      <path
        d="M40.8523 13.8912C40.8409 13.836 40.8139 13.7851 40.7746 13.7446C40.7353 13.7041 40.6853 13.6756 40.6304 13.6625C36.9698 12.7675 28.5117 15.9569 23.9292 20.5375C23.1118 21.3482 22.3667 22.2286 21.7023 23.1687C20.2892 23.0437 18.876 23.1481 17.6717 23.6731C14.2735 25.1687 13.2842 29.0712 13.0085 30.75C12.9929 30.8419 12.9988 30.9361 13.0258 31.0254C13.0527 31.1146 13.1 31.1963 13.1639 31.2642C13.2278 31.332 13.3066 31.3841 13.3941 31.4164C13.4815 31.4486 13.5753 31.4601 13.6679 31.45L19.1248 30.8481C19.1287 31.2595 19.1535 31.6705 19.1992 32.0794C19.2266 32.3633 19.3527 32.6287 19.5554 32.8294L21.6685 34.9375C21.8694 35.1399 22.1347 35.2659 22.4185 35.2937C22.8251 35.3392 23.2338 35.364 23.6429 35.3681L23.0442 40.8181C23.0342 40.9107 23.0458 41.0044 23.0781 41.0918C23.1104 41.1792 23.1625 41.2579 23.2303 41.3218C23.2981 41.3857 23.3798 41.433 23.4689 41.46C23.5581 41.487 23.6523 41.493 23.7442 41.4775C25.4198 41.2087 29.3292 40.2194 30.816 36.8212C31.341 35.6169 31.4485 34.2106 31.3273 32.8044C32.2697 32.1399 33.1524 31.3946 33.9654 30.5769C38.5623 26.0031 41.7335 17.7337 40.8523 13.8912ZM29.3792 25.1206C28.9594 24.7011 28.6735 24.1666 28.5576 23.5846C28.4417 23.0026 28.501 22.3993 28.728 21.851C28.955 21.3027 29.3395 20.8341 29.8329 20.5043C30.3263 20.1746 30.9064 19.9986 31.4998 19.9986C32.0932 19.9986 32.6733 20.1746 33.1667 20.5043C33.6601 20.8341 34.0446 21.3027 34.2716 21.851C34.4986 22.3993 34.5579 23.0026 34.442 23.5846C34.3261 24.1666 34.0402 24.7011 33.6204 25.1206C33.3421 25.3994 33.0115 25.6205 32.6476 25.7714C32.2838 25.9222 31.8937 25.9999 31.4998 25.9999C31.1059 25.9999 30.7158 25.9222 30.3519 25.7714C29.9881 25.6205 29.6575 25.3994 29.3792 25.1206Z"
        fill="white"
      />
      <path
        d="M21.525 36.4644C21.1825 36.8075 20.6331 36.9413 19.9719 37.0556C18.4862 37.3088 17.1744 36.025 17.4412 34.5231C17.5431 33.9538 17.8444 33.1556 18.0319 32.9681C18.0729 32.9279 18.1001 32.8759 18.1098 32.8193C18.1195 32.7627 18.1112 32.7045 18.0859 32.653C18.0607 32.6014 18.0198 32.5591 17.9691 32.5321C17.9185 32.5051 17.8606 32.4948 17.8038 32.5025C16.973 32.6042 16.2001 32.9808 15.6081 33.5725C14.1388 35.0431 14 40.5 14 40.5C14 40.5 19.46 40.3613 20.9294 38.8906C21.5229 38.2991 21.9 37.5251 22 36.6931C22.0231 36.4319 21.705 36.2763 21.525 36.4644Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2090_2035"
        x="-4.7"
        y="-1.2"
        width="98.4"
        height="98.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.85" result="effect1_foregroundBlur_2090_2035" />
      </filter>
      <clipPath id="clip0_2090_2035">
        <rect y="0.5" width="54" height="54" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
