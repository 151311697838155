import { RefObject, useEffect, useRef } from 'react';

function useOutsideClick<T extends HTMLElement>(
  onOutsideClick?: () => void,
  watch = true
): RefObject<T> {
  const ref = useRef<T>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleEvent = (e: any) => {
      if (ref?.current) {
        if (!ref.current.contains(e.target) && onOutsideClick) {
          onOutsideClick();
        }
      }
    };

    const subscribe = () => {
      if (window.PointerEvent) {
        document.addEventListener('pointerdown', handleEvent);
      } else {
        document.addEventListener('mousedown', handleEvent);
        document.addEventListener('touchstart', handleEvent);
      }
    };

    const unsubscribe = () => {
      if (window.PointerEvent) {
        document.removeEventListener('pointerdown', handleEvent);
      } else {
        document.removeEventListener('mousedown', handleEvent);
        document.removeEventListener('touchstart', handleEvent);
      }
    };

    if (watch) {
      subscribe();
      return unsubscribe;
    }
  }, [onOutsideClick, watch, ref]);

  return ref;
}

export default useOutsideClick;
