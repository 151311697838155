import { createWeb3Modal } from '@web3modal/wagmi/react';
import { config } from 'entities/wallet/config';
import { WalletsConfigProvider } from 'entities/wallet/provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WC_PROJECT } from 'shared/lib/constants/global';
import { mainnet } from 'viem/chains';

import App from './app/App';
import reportWebVitals from './reportWebVitals';

createWeb3Modal({
  wagmiConfig: config,
  projectId: WC_PROJECT,
  enableAnalytics: true,
  enableOnramp: true,
  defaultChain: mainnet,
  allowUnsupportedChain: false,
  termsConditionsUrl: 'https://levelq-finance.gitbook.io/home/other/terms-and-conditions',
  privacyPolicyUrl: 'https://levelq-finance.gitbook.io/home/other/privacy-policy',
  themeVariables: {
    '--w3m-z-index': 100,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WalletsConfigProvider>
      <BrowserRouter>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={3_000}
          closeButton={false}
          icon={false}
          hideProgressBar
        />
      </BrowserRouter>
    </WalletsConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
