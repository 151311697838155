import emailjs from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SERVICE_ID, WHITELIST_TEMPLATE } from 'shared/lib/constants/forms';
import { Button } from 'shared/ui/molecules/Button';
import { Input } from 'shared/ui/molecules/Input';

import { validationScheme } from '../../validation';

import css from './index.module.css';

type FormFields = {
  user_name: string;
  user_email: string;
};

type Props = {
  onSuccess: () => void;
};

export const Form = ({ onSuccess }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormFields>({
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: FormFields) => {
    try {
      setIsSending(true);
      await emailjs.send(SERVICE_ID, WHITELIST_TEMPLATE, data);
      onSuccess();
      reset();
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };
  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.fields}>
        <Input
          error={!!errors.user_name?.message}
          placeholder="Name"
          name="user_name"
          type="text"
          RHFRegister={register('user_name')}
          fieldSize="l"
        />
        <Input
          error={!!errors.user_email?.message}
          placeholder="Email"
          type="email"
          RHFRegister={register('user_email')}
          fieldSize="l"
        />
      </div>
      <Button className={css.send} disabled={isSending || !isValid} type="submit">
        Join Waitlist
      </Button>
    </form>
  );
};
