import cx from 'classnames';
import { NavigationItem } from 'entities/types/common';
import { NavbarSocialIcon } from 'shared/ui/atoms/icons/NavbarSocialIcon';

import css from './index.module.css';

type Props = {
  isOpen: boolean;
  items: NavigationItem[];
  setIsOpen: (state: boolean) => void;
};

export const Submenu = ({ isOpen, items, setIsOpen }: Props) => (
  <div
    className={cx(css.submenu, {
      [css.submenu__show]: isOpen,
    })}
  >
    <ul className={css.items}>
      {items?.map(({ title, link, icon }) => (
        <li key={title}>
          <a
            className={cx(css.link, 'title-medium-16')}
            target="_blank"
            href={link}
            onClick={() => setIsOpen(false)}
            rel="noreferrer"
          >
            {icon && <NavbarSocialIcon className={css.link_icon} name={icon} />}
            {title}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
