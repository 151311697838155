import { SocialMedia } from 'entities/types/enum';
import { ComponentProps, createElement } from 'react';

import { Discord } from './social/Discord';
import { LinkedIn } from './social/LinkedIn';
import { Medium } from './social/Medium';
import { Telegram } from './social/Telegram';
import { XSocial } from './social/XSocial';

const icons = {
  [SocialMedia.Telegram]: Telegram,
  [SocialMedia.X]: XSocial,
  [SocialMedia.Discord]: Discord,
  [SocialMedia.Medium]: Medium,
  [SocialMedia.LinkedIn]: LinkedIn,
};

export type Props = {
  name: SocialMedia;
} & ComponentProps<'svg'>;

export const NavbarSocialIcon = ({ name, className, ...props }: Props) => {
  if (!name) {
    return null;
  }

  if (!icons[name]) {
    return null;
  }

  return createElement(icons[name], { ...props, className });
};
