import cx from 'classnames';

import { Card } from './components/Card';
import { BoostedYield } from './components/icons/BoostedYield';
import { PlatformRewards } from './components/icons/PlatformRewards';
import { VotingPower } from './components/icons/VotingPower';
import css from './index.module.css';

const cards: {
  title: string;
  description: string;
  bg: 'type' | 'total-supply' | 'initial-release' | 'tge';
}[] = [
  {
    title: 'Type',
    description: 'Hybrid Vote-Escrowed Model',
    bg: 'type',
  },
  {
    title: 'Total Supply',
    description: '1 Bn LVLQ',
    bg: 'total-supply',
  },
  {
    title: 'Initial Release',
    description: '93 Mn LVLQ',
    bg: 'initial-release',
  },
  {
    title: 'TGE',
    description: 'Q3 2024',
    bg: 'tge',
  },
];

const list = [
  {
    title: 'Boosted Yield',
    icon: <BoostedYield />,
  },
  {
    title: 'Platform Rewards',
    icon: <PlatformRewards />,
  },
  {
    title: 'Voting Power',
    icon: <VotingPower />,
  },
];

export const TokenSection = () => (
  <section className={css.wrapper}>
    <div className="content">
      <h2 className={css.title}>The LVLQ token: boost your yield</h2>
      <div className={css.info}>
        <div className={css.description}>
          <h4 className={cx(css.description_title, 'title-medium-22')}>
            Buy and trade LVLQ token freely on DEX/CEX
            <br />
            Lock your LVLQ tokens and get veLVLQ tokens
          </h4>
          <h4 className={cx(css.description_subtitle, 'title-medium-22')}>
            Use your veLVLQ token to get:
          </h4>
          <ul className={cx(css.cards__mobile)}>
            {cards.map((card) => (
              <li key={card.title}>
                <Card {...card} />
              </li>
            ))}
          </ul>
          <ul className={css.list}>
            {list.map(({ title, icon }) => (
              <li key={title} className={css.list_item}>
                {icon}
                <span className="title-medium-22">{title}</span>
              </li>
            ))}
          </ul>
        </div>
        <ul className={css.cards}>
          {cards.map((card) => (
            <li key={card.title}>
              <Card {...card} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);
