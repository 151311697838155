export const truncateStr = (str: string, firstPartrCount = str.length, endPartCount = 0) => {
  if (str.length <= firstPartrCount + endPartCount) {
    return str;
  }

  const firstPortion = str.slice(0, firstPartrCount);
  const endPortion = str.slice(-endPartCount);

  return `${firstPortion}...${endPortion}`;
};
