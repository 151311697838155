import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { NAV_ITEMS } from 'shared/lib/constants/links';
import { truncateStr } from 'shared/lib/utils/helpers/truncateStr';
import { CopyIcon } from 'shared/ui/atoms/icons/CopyIcon';
import { LogoutIcon } from 'shared/ui/atoms/icons/LogoutIcon';
import { Button } from 'shared/ui/molecules/Button';

import { NavItem } from './components/NavItem/NavItem';
import css from './index.module.css';

type Props = {
  onWLBtnClick: () => void;
  onWalletBtnClick: () => void;
  address?: `0x${string}`;
  onLogoutClick: () => void;
  handleCopyClick: () => void;
};

export const Navbar = ({
  onWLBtnClick,
  onWalletBtnClick,
  address,
  onLogoutClick,
  handleCopyClick,
}: Props) => {
  const { pathname } = useLocation();

  return (
    <nav className={css.wrapper}>
      <ul className={css.links}>
        {NAV_ITEMS.map((el) => (
          <li key={el.title}>
            <NavItem selected={pathname === el.to} {...el} />
          </li>
        ))}
      </ul>
      <div className={css.controls}>
        <Button variant="secondary" onClick={onWLBtnClick}>
          Join Waitlist
        </Button>
        {!address && <Button onClick={onWalletBtnClick}>Connect Wallet</Button>}
        {address && (
          <div className={css.address_wrapper}>
            <Button className={css.address} onClick={handleCopyClick}>
              {truncateStr(address, 6, 5)}
              <CopyIcon className={css.copy_icon} />
            </Button>
            <input
              style={{
                display: 'none',
              }}
              type="text"
              value={address}
              readOnly
            />
            <button
              onClick={onLogoutClick}
              className={cx(css.logout, 'title-medium-14')}
              aria-label="logout"
              type="button"
            >
              Logout
              <LogoutIcon />
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};
