import cx from 'classnames';
import { useState } from 'react';

import { BaseModal } from '../BaseModal';

import { Form } from './components/Form';
import { Success } from './components/Success';
import css from './index.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isSuccess?: boolean;
};

export const WhitelistModal = ({ isOpen, onClose, isSuccess }: Props) => {
  const [step, setStep] = useState<'input' | 'success'>('input');

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setStep('input');
      }}
    >
      <h2 className={cx(css.title, 'title-bold-32')}>Join LevelQ Waitlist.</h2>
      <p className={cx(css.description, 'text-regular-18')}>
        Be the first to receive updates and secure your spot for the beta launch
      </p>
      {step === 'input' && <Form onSuccess={() => setStep('success')} />}
      {(step === 'success' || isSuccess) && <Success onClose={onClose} />}
    </BaseModal>
  );
};
