import cx from 'classnames';
import { ReactNode } from 'react';

import css from './index.module.css';

type Props = {
  title: string;
  description: string;
  icon: ReactNode;
  link: string;
};

export const Card = ({ title, description, icon, link }: Props) => (
  <div className={css.wrapper}>
    <div className={css.inner}>
      {icon}
      <div className={css.info}>
        <h5 className={css.title}>{title}</h5>
        <p className={cx('text-regular-18', css.description)}>{description}</p>
      </div>
    </div>
    <a className={cx('text-regular-14', css.link)} href={link} target="_blank" rel="noreferrer">
      Learn More
    </a>
  </div>
);
