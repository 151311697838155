import cx from 'classnames';
import { ReactNode } from 'react';
import { Modal } from 'react-responsive-modal';
import { CrossIcon } from 'shared/ui/atoms/icons/CrossIcon';
import { LogoIcon } from 'shared/ui/atoms/icons/LogoIcon';

import css from './index.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  closeIcon?: ReactNode;
  isShowCloseBtn?: boolean;
};

export const BaseModal = ({
  isOpen,
  onClose,
  children,
  className,
  closeIcon = <CrossIcon />,
  isShowCloseBtn = true,
}: Props) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    closeIcon={closeIcon}
    showCloseIcon={false}
    center
    classNames={{
      modal: cx(css.modal, className || ''),
      root: css.root,
    }}
    animationDuration={0}
  >
    <div className={css.inner}>
      <div className={css.head}>
        <LogoIcon isShort />
        {isShowCloseBtn && (
          <button onClick={onClose} className={css.close} aria-label="close" type="button">
            <CrossIcon />
          </button>
        )}
      </div>
      <div className={css.body}>{children}</div>
    </div>
  </Modal>
);
